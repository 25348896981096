
import "./Anaestheticevalution.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";
const Anaesthetic_evalution = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);
    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: ".....",
        patient_age: "....",
        patient_gender: "..........",
        patient_phone: "...........",
        patient_address: "........",
        patient_state: "........",
        patient_city: ".........",
        patient_pincode: ".........",
    });
   

    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }


    

    //==========  this fuction work when we select a patient
    const Select = () => {
        
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


  


    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };

    const [doctorsID, setDoctorsID] = useState("");
    let idD = "";
    const getDoctor_id = (id) => {
        setDoctorsID(id);
        console.log(id);
        idD = id;
        // setFormData.doctor_id=id;
        //console.log(doctorsID);
    }
    //  ==================== Doctor list =================



 
    return (
        <><FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
        </FormNavbar>
            <div className="container">
                <div class=" row">
                    <div className="col-sm-8">
                        <FormHeader></FormHeader>

                        <div className="header">
                            <div
                                className="modal fade"
                                id="exampleModal1"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <Attendant></Attendant>

                            </div>
                            <div
                                className="modal fade"
                                id="exampleModal2"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <AttendantSearch></AttendantSearch>
                                {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                                    <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                                </div> */}
                            </div>

                            <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <Patients >
                                </Patients>
                                <div className="select p-2  bg-white  container  rounded  " id="select">
                                    <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                                </div>
                            </div>
                            <div
                                class="modal fade"
                                id="registrationModal"
                                tabIndex="-1"
                                aria-labelledby="registrationModalLabel"
                                aria-hidden="true"
                            >

                                <PatientRegister></PatientRegister>
                            </div>
                        </div>

                    </div>
                    <div class=" col-sm-4">
                        <div class="formboxdetails">
                            <div class="line2">
                                <div class="name">
                                    <p> Patient Name : <b>{patientDetails.patient_name}</b></p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="agesex">
                                    <p>Age : <b>{patientDetails.patient_age}</b>.</p>
                                </div>
                                <div class="agesex">
                                    <p>Sex : <b>{patientDetails.patient_gender}</b></p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">
                                    <p>Bed No. :......................</p>
                                </div>
                                <div class="bed">
                                    <p>IPD No. :.......................</p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">

                                    <p> Consultant :...........................................................</p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">
                                    <p> Reg. No. :..............................................................</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="seprator" />
                <div class="line1">
                    <div class="chief">
                        <p>
                            Dapartment of Anaesthesiology-
                        </p>
                    </div>
                </div>
                <div class="form-container">
                    <div class="form-heading">
                        <h3>ANAESTHETIC RECORD PRE-OPERATIVE EVALUATION FORM</h3>
                    </div>
                    <hr class="thinline"></hr>
                    <div class="form-details">
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    Date
                                </p>
                            </div>
                            <div class="chief">
                                <p>
                                    Time
                                </p>
                            </div>
                            <div class="chief">
                                <p>
                                    Blood Group
                                </p>
                            </div>
                        </div>
                        <hr class="thinline1"></hr>
                        <div class="line1">
                            <div class="provisional">
                                <p>HT.</p>
                            </div>
                            <div class="provisional">
                                <p>WT.</p>
                            </div>
                            <div class="provisional">
                                <p>BMI.</p>
                            </div>
                            <div class="provisional">
                                <p>PULSE.</p>
                            </div>
                            <div class="provisional">
                                <p>BP.</p>
                            </div>
                            <div class="provisional">
                                <p>BR.</p>
                            </div>
                            <div class="provisional">
                                <p>TEMP.</p>
                            </div>
                        </div>
                        <hr class="thinline1"></hr>
                        <div class="line1">
                            <div class="Preventative">
                                <p>DIAGNOSIS</p>
                            </div>
                            <div class="miss">
                                <p>
                                    <table>
                                        <tr>
                                            <td>ALERTS</td>
                                        </tr>
                                        <tr>
                                            <td>Respiratory/haemodynamic support    /   Yes/No</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Allergy / ASA / Consent</td>
                                        </tr>
                                    </table>
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="proposedsurg">
                                <p>PROPOSED SURGERY </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="cardiosystem">
                                <p>
                                    <table>
                                        <tr>
                                            <td><h4>CARDIOVASCULAR SYSTEM</h4>
                                                <li>Hypertension</li>
                                                <li>Cheast Pain</li>
                                                <li>Nyha Grade/Mets</li>
                                                <li>Examination</li>
                                                <li>peripheral Puls</li>
                                            </td>
                                            <td><h4>PULMONARY SYSTEM</h4>
                                                <li>Cough</li>
                                                <li>Asthma</li>
                                                <li>COPD</li>
                                                <li>KOCH's</li>
                                                <li>Smoker</li>
                                                <li>Examination</li>

                                            </td>
                                            <td><h4>CENTRAL NERVOUS SYSTEM</h4>
                                                <li>Paralysis/Stroke</li>
                                                <li>Epilepsy</li>
                                                <li>PIVD./Arthritis</li>
                                                <li>Examination</li>
                                                <hr class="thinline"></hr>
                                                <li>SPIN Examination</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><h4>GIT/HEPATO/RENAL/COAGULATIONDISORDERS</h4></td>
                                            <td><h4>ENDOCRINE SYSTEM</h4>
                                                <li>Diabetes</li>
                                                <li>thyroid</li>
                                                <li>Steroid Use</li>
                                            </td>
                                            <td><h4>PREVIOUSE ANAESTHETIC</h4></td>
                                        </tr>
                                        <tr>
                                            <td><h4>AIRWAY ASSESSMENT</h4>
                                                <li>Mouth Opening</li>
                                                <li>Mallampatti grade</li>
                                                <li>Mentothyoid Distance</li>
                                                <li>Neck Movement</li>
                                                <li>Dentition</li>
                                                <hr class="thinline"></hr>
                                                <li>IMPLANTS : H/O Glaycina % Eye Problems</li>
                                            </td>
                                            <td><h4>DRUG HISTORY/ANYADDICTION</h4>
                                            </td>
                                            <td><h4>FAMILY HISTORY</h4>
                                                <hr class="thinline"></hr>
                                                <h4>Anaesthetic Plan</h4>
                                                <li>GA/Spinal/Epidura/Nerve/Block/MAC</li>
                                                <li>Post operative Pain Relief PAC/Epidural infusion/PEA/MI/IV/ORAL</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><h4>INVESTIGATION</h4>
                                                <li>HB :</li>
                                                <li>WBC :</li>
                                                <li>Platelet :</li>
                                                <li>PT :__________/_________</li>
                                                <li>APPT :__________/_________</li>
                                                <li>Urine R/E/Macroscopic :</li>
                                                <li>Culture :</li>
                                            </td>
                                            <td>
                                                <li>BUN</li>
                                                <li>Create :</li>
                                                <li>NA :</li>
                                                <li>K : CL :</li>
                                                <li>Hco<sub>3</sub> ca:</li>
                                                <li>Glucose: G/PP/R</li>
                                                <li>HBA<sub>1</sub>C</li>
                                                <li>PFT:</li>
                                            </td>
                                            <td>
                                                <li>T3</li>
                                                <li>TSH :</li>
                                                <li>Se,Bil</li>
                                                <li>Total :_______Direct :______</li>
                                                <li>SGOT :_______SGPT :________</li>
                                                <li>ALB :________ALK.Phos:_______</li>
                                                <li>HIV : HBsAg HCV:</li>
                                            </td>
                                        </tr>
                                    </table>
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="dets">
                                <p>
                                    PRE-OP.INSTRUCTION:
                                </p>
                            </div>
                            <div class="dets">
                                <p>
                                    SPECIALITY REVIW:
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="dets">
                                <p>
                                    NI by mouth after
                                </p>
                            </div>
                            <div class="dets">
                                <p>
                                    Acceptance Note:
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="dets">
                                <p>
                                    Drugs/Premedication
                                </p>
                            </div>
                            <div class="dets">
                                <p>
                                    SIGN.of Anaesthetic (PAC):
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="dets">
                                <p>
                                    Further advice :
                                </p>
                            </div>
                            <div class="dets">
                                <p>
                                    (Name in capital)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};


export default Anaesthetic_evalution;