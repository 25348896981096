import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import logo from "../assets/images/logos/logo5.png";
import axios from "axios";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import {
  validateName,
  validateSecPhone,
  validatePassword3,
  validatePassword2,
  validatePassword1,
  validateState,
  validateCity,
  validateAddress,
  validateEmail,
  validatePassword,
  validatePasswordMatch,
  validatePhone,
  validateWeb,
  validatePincode,
  validateCnfPassword,
  validateLogo,
} from "../Validation";
const Registration = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();
  const [loginMesssage, setLoginMesssage] = useState([]);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    logo: null,
    state_id: "",
    city_id: "",
    pincode: "",
    phone_primary: "",
    phone_secondary: "",
    website: "",
    address: "",
  });

  // this is the function of otp verification ====================================================================
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [errorverificationStatus, setErrorVerificationStatus] = useState(null);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [otpMes, setOtpMes] = useState(" ");
  const [otpPhone, setPhoneNumber] = useState(" ");

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const verify = async () => {
    handleModalOpen();

    try {
      const response = await axios.post(`${baseurl}/otp/sendOtp`, {
        phone_number: formValue.phone_primary,
      });
      if (response.data.success === true) {
        console.log("otp send" + response.data);
        setOtpMes(" OTP send successfully to " + otpPhone);
      }
    } catch (error) {
      setOtpMes("Error While Sending OTP");
    }
  };

  const handleOtpChange = (e, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = e.target.value;
    setOtp(updatedOtp);
    // console.log(otp)
  };
  const verifyOTP = async () => {
    try {
      const otpValue = otp.join("");
      const response = await axios.post(`${baseurl}/otp/verifyOtp`, {
        phone_number: formValue.phone_primary,
        generated_otp: otpValue,
      });
      console.log(otpValue);
      if (response.data.success === true) {
        setVerificationStatus(true);
        setIsMobileVerified(true);
        setDisabled(true);
      } else {
        setErrorVerificationStatus(response.data.message);
        setVerificationStatus(true);
        setDisabled(true);
        setIsMobileVerified(true);
      }
    } catch (error) {
      setVerificationStatus(true);
      setIsMobileVerified(true);
      setErrorVerificationStatus("Error verifying OTP. Please try again");
    }
    console.log(disabled);
  };
  //========================== above code was  otp verification code =========================================================

  //=========================== error handling    =========================================================
  const handleChange = (event) => {
    let isValid = true;
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
    // If the changed input is the state select, fetch cities for the selected state
    if (name === "state_id") {
      fetchCities(value);
    }

    const newErrors = { ...errors };
    switch (name) {
      case "name":
        newErrors[name] = validateName(value, true, true)
          ? null
          : "Hospital Name is required";
        isValid = isValid && !newErrors[name];
        break;
      case "email":
        newErrors[name] = validateEmail(value, true, true)
          ? null
          : "Please Enter Correct Email Address";
        isValid = isValid && !newErrors[name];
        break;

      case "password":
        newErrors[name] = validatePassword(value);
        isValid = isValid && !newErrors[name];
        break;
      case "password_confirmation":
        newErrors[name] = validatePassword(value);
        isValid = isValid && !newErrors[name];
        break;
      case "state_id":
        newErrors[name] = validateState(value, true, true)
          ? null
          : "Please Select State";
        isValid = isValid && !newErrors[name];
        break;
      case "city_id":
        newErrors[name] = validateCity(value, true, true)
          ? null
          : "Please Select City";
        isValid = isValid && !newErrors[name];
        break;
      case "address":
        newErrors[name] = validateAddress(value, true, true)
          ? null
          : "Please Enter Address";
        isValid = isValid && !newErrors[name];
        break;
      case "phone_primary":
        newErrors[name] = validatePhone(value);
        isValid = isValid && !newErrors[name];
        break;
      case "pincode":
        newErrors[name] = validatePincode(value, true, true)
          ? null
          : "Please Enter 6 Digit Number";
        isValid = isValid && !newErrors[name];
        break;
      default:
        break;
    }

    if (isValid) {
      event.target.classList.remove("is-invalid");
      event.target.classList.add("is-valid");
    } else {
      event.target.classList.remove("is-valid");
      event.target.classList.add("is-invalid");
    }
    setErrors(newErrors);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file)); // Set image preview
    setFormValue({ ...formValue, logo: file }); // Update logo field in formValue
    const error = validateLogo(file);
    const isValid = !error;
    event.target.classList.toggle("is-valid", isValid);
    event.target.classList.toggle("is-invalid", !isValid);
    setErrors({ ...errors, logo: error });
  };

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${baseurl}/states/`);
      setStates(response.data);
      // Assuming the API response contains an array of states
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `${baseurl}/city/getcitiesByState/${stateId}`
      );
      setCities(response.data);
      // Assuming the API response contains an array of cities for the given stateId
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleRegister = async (e) => {
    // console.log(formValue);
    e.preventDefault();
    const newErrors = {};

    if (isMobileVerified == false) {
      newErrors.phone_primary = "Mobile number is not verified";
      setLoginMesssage("Please verify your phone number");
    }

    if (!validateName(formValue.name, true, true)) {
      newErrors.name = "Hospital Name is required";
    }

    const logoError = validateLogo(formValue.logo);
    if (logoError) {
      newErrors.logo = logoError;
    }

    if (!validateEmail(formValue.email, true, true)) {
      newErrors.email = "Email is required";
    }
    if (!validatePassword1(formValue.password, true, true)) {
      newErrors.password =
        "Password must be at least 8 characters with one special character";
      //  newErrors.password = validatePassword(formValue.password, true, true   );
    }

    if (!validatePassword1(formValue.password_confirmation, true, true)) {
      newErrors.passwordConfirm =
        "Password must be at least 8 characters with one special character";
      //  newErrors.password_confirmation = validatePassword3(formValue.password_confirmation, true, true );
    }

    if (!validateAddress(formValue.address, true, true)) {
      newErrors.address = "Address is required";
    }

    if (!validateCity(formValue.city_id, true, true)) {
      newErrors.city = "Please Select city";
    }

    if (!validateState(formValue.state_id, true, true)) {
      newErrors.state = "Please Select State";
    }

    if (!validatePincode(formValue.pincode, true, true)) {
      newErrors.pincode = "Pincode  is required and must be 6 digit ";
    }
    if (validateWeb(formValue.website, true, true)) {
      newErrors.website = "Website is required";
    }
    if (formValue.password_confirmation != formValue.password) {
      newErrors.passwordConfirm = "Password  does not match";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = new FormData();
      formData.append("name", formValue.name);
      formData.append("email", formValue.email);
      formData.append("password", formValue.password);
      formData.append("password_confirmation", formValue.password_confirmation);
      formData.append("state_id", formValue.state_id);
      formData.append("city_id", formValue.city_id);
      formData.append("pincode", formValue.pincode);
      formData.append("phone_primary", formValue.phone_primary);
      formData.append("phone_secondary", formValue.phone_secondary);
      formData.append("website", formValue.website);
      formData.append("address", formValue.address);
      if (formValue.logo) {
        formData.append("logo", formValue.logo);
      }
      try {
        const response = await axios.post(
          `${baseurl}/hospital/addHospital`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert(response.data.msg);
        navigate("/login");
      } catch (error) {
        // console.log(error.response)
        setLoginMesssage(error.response.data.error);
        alert("Registration Failed  : " + error.response.data.error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const [type, setType] = useState("password");
  const [show, setShow] = useState(true);
  const showPassword = () => {
    setShow(!show);
    if (show) {
      setType("password");
    } else {
      setType("text");
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg  back">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            {" "}
            <img src={logo} className="logoNav me-2" alt="" />
            Suvidha Form
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      <div className="container p-3">
        <div className="row p-4 bg-body-tertiary rounded">
          <h4 className="text-center pb-4 login_heading">
            {" "}
            Hospital Registration{" "}
          </h4>
          <Form onSubmit={handleRegister}>
            <div className="row">
              <FormGroup className="col-sm-6">
                <Label for="hospitalNam">Hospital Name</Label>
                <Input
                  name="name"
                  placeholder="Hospital Name "
                  type="name"
                  required
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.name}</span> && (
                    <span className="text text-danger small">
                      {errors.name}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>

              <FormGroup className="col-sm-4">
                <Label for="exampleFile">File</Label>
                <Input
                  id="exampleFile"
                  name="logo"
                  type="file"
                  required
                  onChange={handleImageChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.logo}</span> && (
                    <span className="text text-danger small">
                      {errors.logo}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-sm-2">
                {selectedImage && (
                  <img
                    src={selectedImage}
                    width="100px"
                    height="100px"
                    alt="Hospital Logo"
                  />
                )}
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-sm-6">
                <Label for="exampleEmail">Hospital Email</Label>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder="example@email.com"
                  type="email"
                  required
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.email}</span> && (
                    <span className="text text-danger small">
                      {errors.email}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>

              <FormGroup className="col-sm-4">
                <Label for="typePhone">Phone Number</Label>
                <Input
                  id="typePhone"
                  name="phone_primary"
                  placeholder="Enter Phone Number"
                  type="tell"
                  required
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.phone1}</span> && (
                    <span className="text text-danger small">
                      {errors.phone1}
                    </span>
                  )}
                  {<span className="small">{errors.phone_primary}</span> && (
                    <span className="text text-danger small">
                      {errors.phone_primary}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-sm-2">
                <Label for="text">.</Label>
                {isMobileVerified ? (
                  <span className="mainHeading text-success d-flex">
                    Verified &#10004;
                  </span>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary form-control"
                      onClick={verify}
                    >
                      Verify OTP
                    </button>
                  </>
                )}
                {/* <button type="button" className='btn btn-primary form-control' onClick={verify}>Verify</button> */}

                {isModalOpen && (
                  <div className="modal" style={modalStyle}>
                    <div className="modal-content" style={modalContentStyle}>
                      <span className="text text-center">{otpMes}</span>
                      <span
                        className=" small close btn-close"
                        onClick={handleModalClose}
                      ></span>

                      <div className="otp-Form">
                        {verificationStatus ? (
                          <>
                            <span className="mainHeading text-success">
                              OTP Verified{" "}
                              <span className="text-success text-center">
                                &#10004;
                              </span>
                            </span>
                            <p className="resendNote fs-2">
                              <button
                                className="resendBtn btn btn-outline-success"
                                onClick={handleModalClose}
                              >
                                Close
                              </button>
                            </p>
                          </>
                        ) : (
                          <>
                            <span className="mainHeading">Enter OTP</span>
                            <p className="otpSubheading">
                              We have sent a verification code to your mobile
                              number
                            </p>
                            <div className="inputContainer">
                              <input
                                required
                                maxLength="1"
                                type="text"
                                className="otp-input"
                                value={otp[0]}
                                onChange={(e) => handleOtpChange(e, 0)}
                              />
                              <input
                                required
                                maxLength="1"
                                type="text"
                                className="otp-input"
                                value={otp[1]}
                                onChange={(e) => handleOtpChange(e, 1)}
                              />
                              <input
                                required
                                maxLength="1"
                                type="text"
                                className="otp-input"
                                value={otp[2]}
                                onChange={(e) => handleOtpChange(e, 2)}
                              />
                              <input
                                required
                                maxLength="1"
                                type="text"
                                className="otp-input"
                                value={otp[3]}
                                onChange={(e) => handleOtpChange(e, 3)}
                              />
                            </div>
                            <button
                              className="verifyButton"
                              type="submit"
                              onClick={verifyOTP}
                            >
                              Verify
                            </button>
                            <p className="resendNote">
                              Didn't receive the code?{" "}
                              <button className="resendBtn" onClick={verify}>
                                Resend Code
                              </button>
                            </p>
                            <span className="text text-danger small">
                              {" "}
                              {errorverificationStatus}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-sm-6">
                <Label htmlFor="">Secondary Phone </Label>
                <Input
                  id="typePhone"
                  name="phone_secondary"
                  placeholder="Enter Phone Number"
                  type="tell"
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid></FormFeedback>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label htmlFor="">Website</Label>
                <Input
                  type="text"
                  name="website"
                  placeholder="Enter Website"
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.website}</span> && (
                    <span className="text text-danger small">
                      {errors.website}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>
            </div>
            <FormGroup className="col-sm-12">
              <Label for="address"> Address</Label>
              <Input
                type="textarea"
                name="address"
                placeholder="Enter Address"
                required
                onChange={handleChange}
              />
              <FormFeedback valid></FormFeedback>
              <FormFeedback invalid>
                {<span className="small">{errors.address}</span> && (
                  <span className="text text-danger small">
                    {errors.address}
                  </span>
                )}
              </FormFeedback>
            </FormGroup>
            <div className="row">
              <FormGroup className="col-sm-4">
                <Label>State</Label>
                <select
                  name="state_id"
                  value={formValue.state_id}
                  className="form-control"
                  onChange={handleChange}
                >
                  <option>Please Select State</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {" "}
                  {<span className="small">{errors.state}</span> && (
                    <span className="text text-danger small">
                      {errors.state}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>

              <FormGroup className="col-sm-4">
                <Label>City</Label>
                <select
                  name="city_id"
                  value={formValue.city_id}
                  className="form-control"
                  onChange={handleChange}
                >
                  <option> Please Select City </option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {" "}
                  {<span className="small">{errors.city}</span> && (
                    <span className="text text-danger small">
                      {errors.city}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>

              <FormGroup className="col-sm-4">
                <Label>Pincode</Label>
                <Input
                  type="text"
                  name="pincode"
                  required
                  onChange={handleChange}
                />
                <FormFeedback valid></FormFeedback>
                <FormFeedback invalid>
                  {" "}
                  {<span className="small">{errors.pincode}</span> && (
                    <span className="text text-danger small">
                      {errors.pincode}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-sm-6">
                <Label for="examplePassword">Password</Label>
                <div className="d-flex gap-1">
                  <Input
                    type={type}
                    name="password"
                    required
                    onChange={handleChange}
                  />
                  {show ? (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          showPassword();
                        }}
                        className="border-0 bg-none"
                      >
                        <i class="bi bi-eye-slash fs-4"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          showPassword();
                        }}
                        className="border-0 bg-none"
                      >
                        <i class="bi bi-eye-fill fs-4"></i>
                      </button>
                    </>
                  )}
                </div>
                <FormFeedback valid>Password is strong</FormFeedback>
                <FormFeedback invalid>
                  {<span className="small">{errors.password}</span> && (
                    <span className="text text-danger small">
                      {errors.password}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label for="examplePassword"> Confirm Password</Label>
                <Input
                  type={type}
                  name="password_confirmation"
                  required
                  onChange={handleChange}
                />
                <FormFeedback valid>Password is strong</FormFeedback>
                <FormFeedback invalid>
                  {(
                    <span className="small error">
                      {errors.password_confirmation}
                    </span>
                  ) && (
                    <span className="text text-danger small error">
                      {errors.password_confirmation}
                    </span>
                  )}
                  {(
                    <span className="small error">
                      {errors.passwordConfirm}
                    </span>
                  ) && (
                    <span className="text text-danger small error">
                      {errors.passwordConfirm}
                    </span>
                  )}
                </FormFeedback>
              </FormGroup>

              <div className="col-sm-6 mt-3 field padding-bottom--24">
                <p className="text-danger">{loginMesssage}</p>
                <button type="submit" className="signup-button btn btn-primary">
                  Register
                </button>
              </div>
            </div>
          </Form>
          <p className="mt-4">
            Already have an account? <Link to="/login">Login Here</Link>
          </p>
        </div>
      </div>
      <div className="copyright text-center m-0">
        {" "}
        Developed By @{" "}
        <Link
          to="https://tecraki.io"
          className="text-decoration-none  text-secondary fw-semibold"
        >
          Tecraki Technology Solutions PVT. LTD.
        </Link>
      </div>
    </>
  );
};
const modalStyle = {
  display: "block" /* Ensure the modal is visible */,
  position: "fixed",
  zIndex: "1",
  left: "0",
  top: "0",
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const modalContentStyle = {
  backgroundColor: "#fefefe",
  margin: "15% auto",
  padding: "20px",
  border: "1px solid #888",
  width: "30%",
};
export default Registration;
