
import "./Nutritionscreening.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar";
import Attendant from "../Attendant";
import Patients from "../Patients";
import PatientRegister from "../PatientRegister";
import AttendantSearch from "../AttendantSearch";
import FormHeader from "../../FormHeader";
const Nurition_screening = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);
   
    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: ".....",
        patient_age: "....",
        patient_gender: "..........",
        patient_phone: "...........",
        patient_address: "........",
        patient_state: "........",
        patient_city: ".........",
        patient_pincode: ".........",
    });
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })

    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }


    const [doctorsID, setDoctorsID] = useState("");
    let idD = "";
    const getDoctor_id = (id) => {
        setDoctorsID(id);
        console.log(id);
        idD = id;
        // setFormData.doctor_id=id;
        //console.log(doctorsID);
    }

    //==========  this fuction work when we select a patient
    const Select = () => {
        removeAttendant();
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


    const removePatientData = () => {
        setPatientDetails({
            id: "",
            patient_name: "",
            patient_age: "",
            patient_gender: "",
            patient_phone: "",
            patient_address: "",
            patient_state: "",
            patient_city: "",
            patient_pincode: "",
        });
    }
    // ================ select attendant =================



    const select = async () => {
        removePatientData();
        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
            const attendantId = localStorage.getItem('attendantId');
            const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
            setAttendantData(response.data.attendant);
            console.log(response.data.attendant);

        } catch (error) {
            alert(error.message);
        }

    }

    const removeAttendant = () => {
        setAttendantData({
            name: "",
            address: "",
            occupation: "",
            telephone: "",
            relation_with_patient: "",
            patient_id: "",
            signature: "",
        })
    }
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    //  ==================== Doctor list =================



    const PatientAddress = patientDetails.patient_address + " " + patientDetails.patient_pincode + " " + patientDetails.patient_city;
    const AttendantAddress = attendant.address;
    return (
        <div>
            <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
            </FormNavbar>
            <div className="container">
                <div className="row">
                    <FormHeader></FormHeader>

                </div>
                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>
                <hr class="seprator" />
                <div class="form-container">
                    <div class="form-heading">
                        <h3>NUTRITION ASSESSMENT SCREENING FORM</h3>
                    </div>
                    <div class="form-details">
                        <div class="d-flex">
                            <div class="name">
                                <p> Patient Name : <b>{patientDetails.patient_name}</b></p>
                            </div>
                            <div class="doctor">
                                <p>Doctor Name : <b>{doctors}</b></p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="serial">
                                <p> Age : <b>{patientDetails.patient_age}</b></p>
                            </div>
                            <div class="serial">
                                <p>Sex : <b>{patientDetails.patient_gender}</b></p>
                            </div>
                            <div class="serial">
                                <p>Ward No. : ......................... </p>
                            </div>
                            <div class="serial">
                                <p>IDP No. : ......................... </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="diagnosis">
                                <p>
                                    Diagnosis : ...........................
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="local">
                                <p>
                                    Height :............
                                </p>
                            </div>
                            <div class="local">
                                <p>
                                    CM :.................
                                </p>
                            </div>
                            <div class="local">
                                <p>
                                    Weight :............
                                </p>
                            </div>
                            <div class="local">
                                <p>
                                    KG :................
                                </p>
                            </div>
                            <div class="local">
                                <p>
                                    BMI :...........
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="sonof">
                                <p>
                                    Religious Belief : No Onion / No Garlic / Veg.Non Veg / Vegetarian
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="sonof">
                                <p>
                                    Food Aliegies :..............................................................
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="sonof">
                                <p>
                                    Diet Prescription Instruction by Treating Consultant : Yes/No (Specific if any..........)
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="sonof">
                                <p>
                                    R/T Feed / Liquid / Clear Fluids / Semi solids / Soft / Normal / Full Diet / NPO
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="special">
                                <p>
                                    Special (Paediatrics) :-
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="list4">
                                <ol type="a">
                                    <li>High Protien/High Fat</li>
                                    <li>Fluid Restriction</li>
                                    <li>Feeding</li>
                                    <li>Supplement(if any).............</li>
                                </ol>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="special">
                                <p>
                                    Centena :- (pick all that apply)
                                </p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <table class="width">
                                <tr>
                                    <th>Adult</th>
                                    <th>Pediatrics</th>
                                </tr>
                                <tr>
                                    <td>Weight Loos  5kg./Month</td>
                                    <td>Malnutrition</td>
                                </tr>
                                <tr>
                                    <td>Difficulty Chewing and Swallowing</td>
                                    <td>UNEXPALINED wEIGHT lOSS</td>
                                </tr>
                                <tr>
                                    <td>Diabetic Mellitus</td>
                                    <td>Inborn Error Of Metabolism</td>
                                </tr>
                                <tr>
                                    <td>Renal,Failure</td>
                                    <td>Renal Failure</td>
                                </tr>
                                <tr>
                                    <td>Hepatic Dysfunction</td>
                                    <td>Hepatic Dysfunction</td>
                                </tr>
                                <tr>
                                    <td>Cardiac Disorder</td>
                                    <td>Cardiac Disorder</td>
                                </tr>
                                <tr>
                                    <td>Malnutrition / Cachexia</td>
                                    <td>Diarrea and / Or Vomiting</td>
                                </tr>
                                <tr>
                                    <td>Diarrehea  and/ or Vomiting</td>
                                    <td>New Onset Diabetes</td>
                                </tr>
                                <tr>
                                    <td>Multi birth And/Or Lactating</td>
                                    <td>Obesity</td>
                                </tr>
                                <tr>
                                    <td>Peptic Ulcer Disease</td>
                                    <td>Diagnosed Cancer And Undergoing Treatment</td>
                                </tr>
                                <tr>
                                    <td>Diagnosed Cancer And Undergoing Treatment</td>
                                    <td>In Weaning Period</td>
                                </tr>
                                <tr>
                                    <td>Enteral Or Parenteral Nutrition Support</td>
                                    <td>Admitted in the ICU/Nursery</td>
                                </tr>
                                <tr>
                                    <td>Weight Gain</td>
                                    <td>Enteral Or Parenteral Nutrition Support</td>
                                </tr>
                                <tr>
                                    <td>Hypertension</td>
                                    <td>Cardiac Failure</td>
                                </tr>
                                <tr>
                                    <td>koch Lung/Coad/Asthma</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Hypoglycaemia</td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint}>
                    Print Form
                </button>
            </div>
        </div>
    );
};

export default Nurition_screening;