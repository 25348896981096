import React from 'react'
import { useState, useEffect } from "react";
import axios from 'axios';
import "./HivTest.css";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import AttendantSearch from '../AttendantSearch';
import PatientRegister from "../PatientRegister"
import FormHeader from '../../FormHeader';
const Hiv_test = (doctor1,doctorID) => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState({});
  const [id, setid] = useState({ id: "" })
  const [doctors, setDoctors] = useState([]);
  
 

  const [patientDetails, setPatientDetails] = useState({
    id: 6,
    patient_name: "",
    patient_age: "",
    patient_gender: "",
    patient_phone: "",
    patient_address: "",
    patient_state: "",
    patient_city: "",
    patient_pincode: "",
  });
  //  this us
 

  const handlechageDoctor = (data) => {
    console.log(data);
    setDoctors(data);
    // setSelectedDoctor(data);
    // handleDoctorSelect();
  }

  const [doctorsID, setDoctorsID] = useState("");
  let idD = "";
  const getDoctor_id = (id) => {
    setDoctorsID(id);
    console.log(id);
    idD = id;
    // setFormData.doctor_id=id;
    //console.log(doctorsID);
  }
  

  //  attendant details 
  const [attendant, setAttendantData] = useState({
    name: "",
    address: "",
    occupation: "",
    telephone: "",
    relation_with_patient: "",
    patient_id: "",
    signature: "",
  })

  const handlePrint = () => {
    getDate();
    window.print();
  };
  
 
  //==========  this fuction work when we select a patient
  const Select = () => {
    removeAttendant();
    const savedPatientDetails = localStorage.getItem('patientDetails');
    if (savedPatientDetails) {
      const pData = JSON.parse(savedPatientDetails);
      setPatientDetails(pData.patient);
      console.log(pData.patient); // Add this line to check if data is retrieved correctly
    }
  };


  const removePatientData = () => {
    setPatientDetails({
      id: "",
      patient_name: "",
      patient_age: "",
      patient_gender: "",
      patient_phone: "",
      patient_address: "",
      patient_state: "",
      patient_city: "",
      patient_pincode: "",
    });
  }
  // ================ select attendant =================



  const select = async () => {
    removePatientData();
    const savedAttendantDetails = localStorage.getItem('attendant');
    if (savedAttendantDetails) {
      const aData = JSON.parse(savedAttendantDetails);
      setid(aData.id);
    }
    getAttendant();
    // console.log(id);

  }

  const getAttendant = async () => {
    try {
      const attendantId = localStorage.getItem('attendantId');
      const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
      setAttendantData(response.data.attendant);
      console.log(response.data.attendant);

    } catch (error) {
      alert(error.message);
    }

  }

  const removeAttendant = () => {
    setAttendantData({
      name: "",
      address: "",
      occupation: "",
      telephone: "",
      relation_with_patient: "",
      patient_id: "",
      signature: "",
      status: "married"
    })
  }
  const [date, setDate] = useState("");

  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "/" + month + "/" + year;
    setDate(currentDate);
    console.log(currentDate);
  
  }

  const [previewDisabled, setPreviewDisabled] = useState(true);
  const submitForm = async (e) => {
    e.preventDefault();
    let PID = patientDetails.id;
    try {
      if (patientDetails.id === "") {
        PID = attendant.id;
      } else if (doctorsID === "" || PID === "") {
        alert("Please select patient and doctor");
        return;
      }
      const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, {
        doctor_id: doctorsID,
        patient_id: PID,
        date: date,
        form_id: "1"
      });
      setPreviewDisabled(false);
      console.log(res);
      alert(res.data.msg);
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <div>
      <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
      </FormNavbar>
      <div className="header container">
        <div className="main-header">
          <FormHeader></FormHeader>
            <div
              className="modal fade"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <Attendant></Attendant>

            </div>

            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <Patients >
              </Patients>
              <div className="select p-2  bg-white  container  rounded  " id="select">
                <button className="btn btn-primary " data-bs-dismiss="modal"
                  aria-label="Close" onClick={Select}>Select This Patient</button>
              </div>
            </div>
            <div
              className="modal fade"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <AttendantSearch></AttendantSearch>
              <div className="select p-2  bg-white  container  rounded  " id="select">
                <button className="btn btn-primary " data-bs-dismiss="modal"
                  aria-label="Close" onClick={getAttendant}>Select This Attendant</button>
              </div>
            </div>

            <div
              class="modal fade"
              id="registrationModal"
              tabIndex="-1"
              aria-labelledby="registrationModalLabel"
              aria-hidden="true"
            >

              <PatientRegister></PatientRegister>
            </div>

          <div className="container">
            <div class="form-heading">
              <h3>CONSENT FORM FOR HIV TESTING</h3>
            </div>
            <div class="">
              <div class=" text-start mb-4">
                <p>
                  I understand that the test result will part of my confidential medical record.
                  I have been infromed about AIDS(HIV) antibody test see the attached infromation.
                  I had certain question to ask whic have been satisfactorily answered.
                </p>
              </div>
              <div class="row">
                <div class="col-sm-6 text-start">
                  <p> Patient Name : <b>{patientDetails.patient_name}</b></p>
                </div>
                <div class="sex col-sm-6">
                  <p>Age/Sex: <b>{patientDetails.patient_age + " / " + patientDetails.patient_gender}</b> </p>
                </div>
                <div class=" row">
                  <div class="col-sm-6 text-start">
                    <p>Marital Status : {patientDetails.status}</p>
                  </div>
                  <div class="col-sm-6">
                    <p>Referred by : <b>{doctors}</b> </p>
                  </div>
                </div>
                <div class=" text-start mb-3 mt-3">

                  <p>I , hereby , give my consent for the test to be conducted
                    on me in order to ascertain my HIV
                    serostatus
                  </p>

                </div>
                <div class="row">
                  <div class="text-start col">
                    <p>Patient Signature </p>
                  </div>
                  <div class="col">
                    <p>(In case of minor-Parents Signature)</p>
                  </div>
                </div>

                <div class="text-start">
                  <p>
                    (In case of unconscious patient Signature of next of kin)
                  </p>

                </div>
                <div class="row">
                  <div class="col text-start">
                    <p>
                      Specimen :-<b>{attendant.name}</b>
                    </p>
                  </div>
                  <div class="col">
                    <p>Clinical Details :- ...........................................</p>
                  </div>
                </div>
                <div class="text-start mt-4">

                  <p>
                    This is to certify that the consent form has been signed in my presence and patient
                    has been given pre testing counselling and post test counsellingis ensured.
                  </p>

                </div>
                <div class="">
                  <div class="text-start">
                    <p>
                      Doctors Signature
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button">
        <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
        <button className="btn btn-primary" onClick={submitForm} disabled={!doctorsID || !patientDetails.id}>
          Submit
        </button>
        <button className="btn btn-primary ms-3" onClick={handlePrint} disabled={previewDisabled}>
          Preview Form
        </button>
        {/* <button className="btn btn-primary" onClick={handlePrint}>
          Print Form
        </button> */}
      </div>
    </div>
  );
};

export default Hiv_test;
