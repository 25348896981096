
import "./Nursingclinic.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";


const Nursing_clinic = (doctor1) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);
    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: ".....",
        patient_age: "....",
        patient_gender: "..........",
        patient_phone: "...........",
        patient_address: "........",
        patient_state: "........",
        patient_city: ".........",
        patient_pincode: ".........",
    });
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })

   



    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }

   
    const Select = () => {
        removeAttendant();
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


    const removePatientData = () => {
        setPatientDetails({
            id: "",
            patient_name: "",
            patient_age: "",
            patient_gender: "",
            patient_phone: "",
            patient_address: "",
            patient_state: "",
            patient_city: "",
            patient_pincode: "",
        });
    }

    // ================ select attendant =================



    const select = async () => {
        removePatientData();
        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
            const attendantId = localStorage.getItem('attendantId');
            const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
            setAttendantData(response.data.attendant);
            console.log(response.data.attendant);

        } catch (error) {
            alert(error.message);
        }

    }

    const removeAttendant = () => {
        setAttendantData({
            name: "",
            address: "",
            occupation: "",
            telephone: "",
            relation_with_patient: "",
            patient_id: "",
            signature: "",
        })
    }
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };

    //  ==================== Doctor list =================

    const PatientAddress = patientDetails.patient_address + " " + patientDetails.patient_pincode + " " + patientDetails.patient_city;
    const AttendantAddress = attendant.address;
    return (
        <div>
            <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor}>
            </FormNavbar>
            <div className="main-header container">
             <FormHeader></FormHeader>

                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>
                <hr class="seprator" />
                <div class="form-container">
                    <div class="name-of-form">
                        <h3>NURSING CLINICAL ASSESMENT</h3>
                    </div>

                    <div class="vitals">
                        <div class="date">
                            <div class="vit">
                                <p><b>VITALS:-</b></p>
                            </div>
                            <div class="dat">
                                <p>Date ...........</p>
                            </div>
                        </div>
                        <div class="fields">
                            <div class="temp">
                                <p>TEMPRATURE: ....</p>
                            </div>
                            <div class="pules">
                                <p>PULES .....</p>
                            </div>
                            <div class="bp">
                                <p>B.P ...........</p>
                            </div>
                            <div class="respi">
                                <p>RESPIRATION ......</p>
                            </div>
                            <div class="spo2">
                                <p>SPO2 ......</p>
                            </div>
                        </div>
                    </div>
                    <div class="fall-asses">
                        <p><b>FALL ASSESMENT :</b></p>
                        <div class="details">
                            <div class="det1">
                                <p>AGE: <b>{patientDetails.patient_age}</b></p>
                                <p>IMPPAIRED JUDGEMENT</p>
                                <p>SENSORY DEFICT</p>
                            </div>
                            <div class="det1">
                                <p>PREVIOUS FALL</p>
                                <p>CVS/CNS MEDICATION</p>
                                <p>MUSCULAR WEAKNESS</p>
                            </div>
                            <div class="det2">
                                <p>BOWEL/BLADDER NEEDS </p>
                                <p>OTHER</p>
                            </div>
                        </div>
                    </div>
                    <div class="pain-asses">
                        <p><b>PAIN ASSESMENT :-</b></p>
                        <div class="pain1">
                            <p>ACUTE (\6 WEEKS)</p>
                            <p>CHRONIC (/6 WEEKS)</p>

                        </div>
                        <div class="pain2">
                            <p>NONE</p>
                            <p>ANNOYING 02</p>
                            <p>UNCONFORTABLE</p>
                            <p>MUSCULER WEAKNESS</p>
                        </div>
                    </div>
                    <div class="psyco-status">
                        <p><b>PSYCHOLOGICAL STATUS :-</b></p>
                        <div class="psy1">
                            <p>ANXIOUS</p>
                            <p>DEPRESSESD</p>
                            <p>ANGRY</p>
                        </div>
                        <div class="psy2">
                            <p>COMBATIVE</p>
                            <p>SLEEP DISORDER</p>
                            <p>OTHER</p>
                        </div>

                    </div>

                    <div class="ability">
                        <p><b>ABILITY TO PERFORM ACTIVITES OF DAILY LIFE</b></p>
                        <div class="acti">
                            <p>ACTIVITY</p>
                            <p>INDEPENDENT</p>
                            <p>ASSISTED</p>
                            <p>DEPENDENT</p>
                        </div>
                        <div class="acti">
                            <p>BATHING</p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                        <div class="acti">
                            <p>DRESSING</p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                        <div class="acti">
                            <p>EATING</p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                        <div class="acti">
                            <p>MOBILE/ WALKING </p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                        <div class="acti">
                            <p>STATUS/ CLIMBING </p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                        <div class="acti">
                            <p>TOILET</p>
                            <p>.........</p>
                            <p>.........</p>
                            <p>.........</p>

                        </div>
                    </div>
                    <div class="social">
                        <p><b>SOCIAL HISTORY</b></p>
                        <div class="soc1">
                            <p>DO YOU SMOKE</p>
                            <p>YES/NO</p>
                            <p>PACKS/DAY  FOR -------- YES</p>

                        </div>
                        <div class="soc1">
                            <p>DO YOU DRINK</p>
                            <p>YES/NO</p>
                            <p>PACKS/DAY  FOR -------- YES</p>

                        </div>
                    </div>
                    <div class="list">
                        <ol>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>
                            <li>
                                <div class="drk1">
                                    <p>LANGUAGE PROBLEM </p>
                                    <p>YES/NO  &nbsp; ACTION NEEDED</p>
                                </div>
                            </li>

                        </ol>
                    </div>

                </div>

            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint}>
                    Print Form
                </button>
            </div>
        </div>
    );
};


export default Nursing_clinic;




