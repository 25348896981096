import React, { createContext, useState } from "react";

export const SelectDoctorContext = createContext(null);

export const SelectDoctorProvider = (props) => {
  const [currentDoctor, setCurrentDoctor] = useState("");

  return (
    <SelectDoctorContext.Provider value={{ currentDoctor, setCurrentDoctor }}>
      {props.children}
    </SelectDoctorContext.Provider>
  );
};
