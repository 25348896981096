import { createContext , useState} from "react";
 export const patientContext = createContext(null);
 export const PatientContextProvider = (props) => {
    const [patient, setPatient] = useState("");
    const [patientID, setPatientID] = useState(null);
    return (
      <patientContext.Provider value={{ patient, setPatient, patientID, setPatientID }}>
        {props.children}
      </patientContext.Provider>
    );
  };
 