
import axios from "axios";
import React from "react";
import { useEffect, useState,  } from "react";
import { Link , useNavigate} from "react-router-dom";
import { Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

const PatientRegister = ({ patient, showModal, toggleModal }) => {
  const baseurl = process.env.REACT_APP_BASE_URL;
const navigate= useNavigate();
  const [phoneValid, setPhoneValid] = useState(false);
  const [pinValid, setPinValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [ageValid, setAgeValid] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [patientid, setPatientId] = useState("..");
  const [formValue, setFormValue] = useState({
    patient_name: "",
    patient_age: "",
    patient_city: "",
    patient_gender: "",
    patient_address: "",
    patient_phone: "",
    patient_state: "",
    patient_pincode: "",
    hospital_id: ""
  });
  const [msg, setMsg] = useState("");

  useEffect(() => {
    fetchStates();
    const id = localStorage.getItem("HID");
    console.log(id);
    formValue.hospital_id = id;
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update form value
    setFormValue({ ...formValue, [name]: value });

    // If the changed input is the state select, fetch cities for the selected state
    if (name === "patient_state") {
      fetchCities(value);
    }

    // Perform validation based on input name using switch statement
    switch (name) {
      case "patient_phone":
        // Validate phone number
        setPhoneValid(validatePhone(value));
        break;
      case "patient_pincode":
        // Validate pincode
        setPinValid(validatePincode(value));
        break;
      // case "patient_name":
      //   // Validate name
      //   setNameValid(validateName(value));
      //   break;
      case "patient_age":
        // Validate age
        setAgeValid(validateAge(value));
        break;
      default:
        break;
    }
  };


  // ===================================  validation regex codde =====================================================
  const validatePhone = (phone) => {
    // Regular expression to match Indian phone numbers
    const phoneRegex = /^[1-9]\d{9}$/;
    return phoneRegex.test(phone);
  };
  const validateName = (name) => {
    // Check if the name is not empty and contains only alphabets and spaces
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
  }
  // Validation function for pincode
  const validatePincode = (pincode) => {
    // Regular expression to match Indian pincodes
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    return pincodeRegex.test(pincode);
  };

  // Validation function for age
  const validateAge = (age) => {
    // Check if the age is a number between 1 and 150
    const ageNum = parseInt(age);
    return !isNaN(ageNum) && ageNum >= 1 && ageNum <= 150;
  };

  // ============================================ above are vaalidation code =============================================





  const fetchStates = async () => {
    try {
      const response = await axios.get(`${baseurl}/states/`);
      setStates(response.data);
      // Assuming the API response contains an array of states
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `${baseurl}/city/getcitiesByState/${stateId}`
      );
      setCities(response.data);
      // Assuming the API response contains an array of cities for the given stateId
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required field is empty
    for (const key in formValue) {
      if (formValue[key] === "") {
        alert("Please fill in all required fields.");
        return;
      }
    }

    if (!phoneValid || !pinValid || !ageValid) {
      alert("Please enter valid information in all fields.");
      return;
    }

    try {
      // console.log(formValue)
      const response = await axios.post(`${baseurl}/patient/addPatient`, formValue);
      if (response.status === 200) {
        alert(response.data.msg);
        setMsg(response.data.msg);

        const id = response.data.patient.id;
        setPatientId(id);

        // Reset form fields
        setFormValue({
          patient_name: "",
          patient_age: "",
          patient_city: "",
          patient_gender: "",
          patient_address: "",
          patient_phone: "",
          patient_state: "",
          patient_pincode: "",

        });

        // Reset validation states
        setPhoneValid(false);
        setPinValid(false);
        setNameValid(false);
        setAgeValid(false);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };


  return (
    <div>
      <Modal isOpen={showModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Pateints</ModalHeader>
        <ModalBody>
          {/* <div className="dashboard" id="dashbaord"> */}
          <div className="p-4 bg-body-tertiary rounded ">
            <h3 className='text-primary  p-3 rounded text-opacity-75'>  Patients Registration </h3>
            <form className="bg-body-tertiary p-3 rounded " onSubmit={handleSubmit}>
              <div className="row">
                <div className="mb-3 col-12">
                  <Label htmlFor="name" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="name"
                    value={formValue.patient_name}
                    name="patient_name"
                    placeholder=" Please enter patients name"
                    required
                    // valid={nameValid}
                    // invalid={!nameValid && formValue.nameValid !== ''}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3 col-4">
                  <label htmlFor="age" className="form-label">
                    Age
                  </label>
                  <Input
                    type="number"
                    className="form-control"
                    id="age"
                    value={formValue.patient_age}
                    name="patient_age"
                    placeholder="Please enter patient age"
                    required
                    valid={ageValid}
                    invalid={!ageValid && formValue.patient_age !== ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 col-8">
                  <Label htmlFor="phone" className="form-Label">
                    Phone Number
                  </Label>
                  <Input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="patient_phone"
                    placeholder="Please enter patient phone number"
                    required
                    value={formValue.patient_phone}
                    valid={phoneValid}
                    invalid={!phoneValid && formValue.patient_phone !== ''}
                    onChange={handleChange}
                  />
                </div>


                <div className="col">
                  <Label htmlFor="">Gender</Label>

                  <Input
                    name="patient_gender"
                    value={formValue.patient_gender}
                    className="form-control"
                    onChange={handleChange}
                    required
                    type="select"
                  >
                    <option value="" > Select </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Input>
                </div>
            

              </div>

              <div className="row">
                <div className="col">
                  <Label htmlFor="address" className="form-Label">
                    Address
                  </Label>
                  <textarea
                    type="text"
                    rows="2"
                    className="form-control"
                    id="address"
                    value={formValue.patient_address}
                    name="patient_address"
                    placeholder="Please Enter Patient Address"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <Label htmlFor="state" className="form-Label">
                    State
                  </Label>
                  <Input
                    name="patient_state"
                    value={formValue.patient_state}
                    className="form-control"
                    onChange={handleChange}
                    type="select"
                  >
                    <option>Please Select State</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.state_name}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="col-md-6">
                  <Label htmlFor="city" className="form-Label">
                    City
                  </Label>
                  <Input
                    name="patient_city"
                    value={formValue.patient_city}
                    className="form-control"
                    onChange={handleChange}
                    type="select"
                  >
                    <option> Please Select City </option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.city_name}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="mb-3 col-6">
                  <Label htmlFor="pincode" className="form-Label">
                    Pincode
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="pincode"
                    value={formValue.patient_pincode}
                    placeholder="Please enter patient Pincode "
                    required
                    valid={pinValid}
                    invalid={!pinValid && formValue.patient_pincode !== ''}
                    name="patient_pincode"
                    onChange={handleChange}
                  />
                </div>

              </div>
              <button type="submit" className="btn btn-primary mt-1">
                Submit
              </button>
              <hr className="mt-3" />
            </form>
              <div className=" row gap-1 mt-2 p-2">
                <div className="col-6 border border-info rounded-3  ">
                  <h5 className="text d-flex mt-1">UHID : {patientid}</h5>
                </div>
                <div className="col-5">
                  <p className="text-success">{msg}</p>
                </div>
              </div>
          </div>
          {/* </div> */}
          <div className="copyright text-center m-0 fixed-bottom">
            Developed By @
            <Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">
              Tecraki Technology Solutions PVT. LTD.
            </Link>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PatientRegister;
