import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { UserContext } from '../context/Usercontext';
import { useNavigate } from 'react-router-dom';

const AddDoctor = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const { user } = useContext(UserContext);
    const hid = user.hospital.id;
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({
        name: '',
        phonenumber: '',
        email: '',
        speciality_id: '',
        hospital_id: hid,
    });

    const [phoneValid, setPhoneValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [specialities, setSpecialities] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSpecialities = async () => {
            try {
                const res = await axios.get(`${baseurl}/speciality/getAllSpecialities`);
                setSpecialities(res.data.specialities); // Update state with specialities array
                setLoading(false);
            } catch (error) {
                console.error('Error fetching specialities:', error);
                setLoading(false);
            }
        };

        fetchSpecialities();
    }, [baseurl]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });

        if (name === 'phonenumber') {
            const phoneRegex = /^\d{10}$/;
            setPhoneValid(phoneRegex.test(value));
        }

        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailValid(emailRegex.test(value));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formValue); // Log the response from the API
            const res = await axios.post(`${baseurl}/doctor/addDoctor`, formValue); // Adjust the API endpoint accordingly
            // Reset the form after successful submission if needed
            if (res.status === 200) {
                setFormValue({
                    name: '',
                    phonenumber: '',
                    email: '',
                    speciality_id: ''
                });
                navigate(`/dashboard/${hid}`);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert(error.response.data.error);
        }
    };

    return (
        <div className='bg-body-tertiary rounded p-3'>
            <h3>Please Add Doctors First</h3>
            <Form onSubmit={handleSubmit} className='row'>
                <FormGroup className='col-6'>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" id="name" value={formValue.name} name="name" placeholder="Enter doctor's name" onChange={handleChange} required />
                </FormGroup>
                <FormGroup className='col-6'>
                    <Label htmlFor="phonenumber">Phone Number</Label>
                    <Input type="tel" id="phonenumber" value={formValue.phonenumber} name="phonenumber" placeholder="Enter doctor's phone number" onChange={handleChange} required valid={phoneValid} invalid={!phoneValid && formValue.phonenumber !== ''} />
                    <FormFeedback valid={phoneValid}>{phoneValid ? null : 'Please enter a valid phone number'}</FormFeedback>
                </FormGroup>
                <FormGroup className='col-6'>
                    <Label htmlFor="email">Email</Label>
                    <Input type="email" id="email" value={formValue.email} name="email" placeholder="Enter doctor's email" onChange={handleChange} required valid={emailValid} invalid={!emailValid && formValue.email !== ''} />
                    <FormFeedback valid={emailValid}>{emailValid ? null : 'Please enter a valid email address'}</FormFeedback>
                </FormGroup>
                <FormGroup className='col-6'>
                    <Label htmlFor="speciality_id">Speciality</Label>
                    <Input type="select" id="speciality_id" value={formValue.speciality_id} name="speciality_id" onChange={handleChange} required>
                        <option value="">Select Speciality</option>
                        {loading ? (
                            <option disabled>Loading...</option>
                        ) : (
                            specialities.map((speciality) => (
                                <option key={speciality.id} value={speciality.id}>{speciality.name}</option>
                            ))
                        )}
                    </Input>
                </FormGroup>
                <Button type="submit" color="primary" className='col-2'>Add</Button>
            </Form>
        </div>
    );
};

export default AddDoctor;
