
import "./Patientfeedback.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";
const Patient_feedback = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;

    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);

    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: ".....",
        patient_age: "....",
        patient_gender: "..........",
        patient_phone: "...........",
        patient_address: "........",
        patient_state: "........",
        patient_city: ".........",
        patient_pincode: ".........",
    });
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })



    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }

    const [doctorsID, setDoctorsID] = useState("");
    let idD = "";
    const getDoctor_id = (id) => {
        setDoctorsID(id);
        console.log(id);
        idD = id;
        // setFormData.doctor_id=id;
        //console.log(doctorsID);
    }

    //==========  this fuction work when we select a patient

    const Select = () => {
        removeAttendant();
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


    const removePatientData = () => {
        setPatientDetails({
            id: "",
            patient_name: "",
            patient_age: "",
            patient_gender: "",
            patient_phone: "",
            patient_address: "",
            patient_state: "",
            patient_city: "",
            patient_pincode: "",
        });
    }

    // ================ select attendant =================



    const select = async () => {
        removePatientData();
        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
            const attendantId = localStorage.getItem('attendantId');
            const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
            setAttendantData(response.data.attendant);
            console.log(response.data.attendant);

        } catch (error) {
            alert(error.message);
        }

    }

    const removeAttendant = () => {
        setAttendantData({
            name: "",
            address: "",
            occupation: "",
            telephone: "",
            relation_with_patient: "",
            patient_id: "",
            signature: "",
        })
    }
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    //  ==================== Doctor list =================



    const PatientAddress = patientDetails.patient_address + " " + patientDetails.patient_pincode + " " + patientDetails.patient_city;
    const AttendantAddress = attendant.address;
    return (
        <div>
            <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
            </FormNavbar>
            <div className="main-header container">
                <div className="row">
                    <FormHeader></FormHeader>
                </div>

                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>
                <hr class="seprator" />
                <div class="form-container">
                    <div class="form-heading">
                        <h3>PATIENT FEEDBACK FORM</h3>
                    </div>
                    <div class="form-details">
                        <div class="line1">
                            <div class="name">
                                <p> Patient Name : <b>{patientDetails.patient_name}</b> </p>
                            </div>
                            <div class="sex">
                                <p>R. No. :.....................................................</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="marital">
                                <p>Dear Patient <br />
                                    We will be grateful,if you can spare some of your valuable time in sharing
                                    you views by filling and returning this form. Your Support Will help us in
                                    serving you better</p>
                            </div>
                        </div>
                        <hr class="seprator1" />
                        <div class="line1">
                            <div class="name">
                                <p> Patient Name : <b>{patientDetails.patient_name}</b> </p>
                            </div>
                            <div class="sex">
                                <p>Date of Visit :.....................................................</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="name">
                                <p> Room No. :................................................</p>
                            </div>
                            <div class="sex">
                                <p>Regn No. :.....................................................</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="name">
                                <p> Mobile No. : <b>{patientDetails.patient_phone}</b></p>
                            </div>
                            <div class="sex">
                                <p>Phone No. :<b>{patientDetails.patient_phone}</b></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="status">
                                <p>Your Basic Ckeck-up Was Done in time : </p>
                            </div>
                            <div class="status">
                                <p> Yes <input type="checkbox" /> </p>
                            </div>
                            <div class="status">
                                <p> NO. <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="status">
                                <p>The actual test performed were as per instructions : </p>
                            </div>
                            <div class="status">
                                <p> Yes <input type="checkbox" /> </p>
                            </div>
                            <div class="status">
                                <p> NO. <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="status">
                                <p>You are coming to our hospital for the test : </p>
                            </div>
                            <div class="status1">
                                <p> 1 <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> 2 <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> 3 <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> 4 <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> 5 <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> More <input type="checkbox" /> </p>
                            </div>
                            <div class="status1">
                                <p> Year <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="signature">
                                <p> </p>
                            </div>
                            <div class="parent_signature">
                                <p>Satisfaction Level(Sacle of 5 To 1)</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>

                                </p>
                            </div>
                            <div class="next">
                                <p>
                                    High 5
                                </p>
                            </div>
                            <div class="next">
                                <p>
                                    4
                                </p>
                            </div>
                            <div class="next">
                                <p>
                                    3
                                </p>
                            </div>
                            <div class="next">
                                <p>
                                    2
                                </p>
                            </div>
                            <div class="next">
                                <p>
                                    Low 1
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    1. Emergency Staff-helpful Polite
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    2. Main reception Staff-helpful & polite
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    3. Nursing Staff-helpful & Polite
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    4. Persona care
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    5. Investigation was comfortable
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    6.Ambulance Service (If availed)
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    7. Waiting Area -comfortable & Clean
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    8. Cleanliness of room / Toilets
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    9. Discharge of room / Toilets
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    10. Room Nursing care
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    11. Room Facility (Bed sheet & pillow Cover etc.)
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    12. Pantry Service (Canteen)
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="next1">
                                <p>
                                    13. What is your Satisfaction Level ?
                                </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p> <input type="checkbox" /> </p>
                            </div>
                            <div class="nextint">
                                <p>  <input type="checkbox" /> </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    14. Would you recommend us to dotor
                                </p>
                            </div>
                            <div class="chief">
                                <p>
                                    Yes / No
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    15. Services provided by the Doctors____________________________
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    16. Your Suggestion for improving our service quality____________________
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    17. Unpleasent Experience, if any
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    Signature_________________
                                </p>
                            </div>
                            <div class="ambulatory">
                                <p>
                                    Patient/Relation(Relation)_____________
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    Name______________
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
};


export default Patient_feedback;
