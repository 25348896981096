import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Link , useNavigate} from 'react-router-dom';
import { UserContext } from '../context/Usercontext';
import AddDoctorsModal from './Adddoctors'; // Import the modal component

const Doctors = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [doctors, setDoctors] = useState([]);
    const { user } = useContext(UserContext);
    const hid = user.hospital.id;

    useEffect(() => {
        
        fetchData();
    }, [hid]); // Include doctors as a dependency
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseurl}/doctor/doctors/hospitals/${hid}`);
            const doctorsWithSerial = response.data.map((doctor, index) => ({
                ...doctor,
                serial: index + 1,
            }));
            setDoctors(doctorsWithSerial);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // State to manage the visibility of the modal
    const [showModal, setShowModal] = useState(false);

    // Function to toggle the modal visibility
    const toggleModal = () => {
        setShowModal(!showModal);
        fetchData();
    };

    const columns = [
        {
            accessorKey: 'serial', // Serial number accessor key
            header: 'S.No.', // Serial number header
            size: 100, // Adjust size as needed
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 150,
        },
        {
            accessorKey: 'phonenumber',
            header: 'Phone Number',
            size: 150,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 200,
        },
        {
            accessorKey: 'speciality_name',
            header: 'Speciality',
            size: 150,
        },
    ];

    return (
        <div>
            <div className="dashboard p-2 mb-3 bg-body-tertiary rounded">
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='text-primary p-3 rounded text-opacity-75'>Doctors List</h3>
                    <button onClick={toggleModal} type="button" className="btn btn-outline-primary">
                        <i className="bi bi-plus-circle">&nbsp; Add Doctors</i>
                    </button>
                </div>
                <MaterialReactTable columns={columns} data={doctors} className="custom-material-table bg-body-tertiary" />
            </div>
            <div className="copyright text-center m-0 fixed-bottom">
                Developed By @
                <Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">
                    Tecraki Technology Solutions PVT. LTD.
                </Link>
            </div>
            {/* Render the modal component with the show/hide state */}
            <AddDoctorsModal showModal={showModal} toggleModal={toggleModal} />
        </div>
    );
};

export default Doctors;
