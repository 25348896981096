
import "./Postplancare.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";
const Post_plan = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState({});
    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [attendantId, setAttendantId] = useState('');
   
    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: ".....",
        patient_age: "....",
        patient_gender: "..........",
        patient_phone: "...........",
        patient_address: "........",
        patient_state: "........",
        patient_city: ".........",
        patient_pincode: ".........",
    });
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })
    

    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }
    const [doctorsID, setDoctorsID] = useState("");
    let idD = "";
    const getDoctor_id = (id) => {
      setDoctorsID(id);
      console.log(id);
      idD = id;
      // setFormData.doctor_id=id;
      //console.log(doctorsID);
    }
   

    //==========  this fuction work when we select a patient
    const Select = () => {
        removeAttendant();
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


    const removePatientData = () => {
        setPatientDetails({
            id: "",
            patient_name: "",
            patient_age: "",
            patient_gender: "",
            patient_phone: "",
            patient_address: "",
            patient_state: "",
            patient_city: "",
            patient_pincode: "",
        });
    }
    // ================ select attendant =================



    const select = async () => {
        removePatientData();
        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
            const attendantId = localStorage.getItem('attendantId');
            const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
            setAttendantData(response.data.attendant);
            console.log(response.data.attendant);

        } catch (error) {
            alert(error.message);
        }

    }

    const removeAttendant = () => {
        setAttendantData({
            name: "",
            address: "",
            occupation: "",
            telephone: "",
            relation_with_patient: "",
            patient_id: "",
            signature: "",
        })
    }
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    //  ==================== Doctor list =================



    const PatientAddress = patientDetails.patient_address + " " + patientDetails.patient_pincode + " " + patientDetails.patient_city;
    const AttendantAddress = attendant.address;
    return (
        <div>
            <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
            </FormNavbar>
            <div className="container">

                <div className="row">
                   <FormHeader></FormHeader>
                </div>

                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>

                <hr class="seprator" />
                <div class="form-container">
                    <div class="form-heading">
                        <h3>POST OPERATIVE PLAN OF CARE</h3>
                    </div>
                    <div class="form-details">
                        <div class="line1">
                            <div class="name">
                                <p>Name of Patient : <b>{patientDetails.patient_name}</b> </p>
                            </div>
                            <div class="sex">
                                <p>Age/Sex:  <b>{patientDetails.patient_age + " / " + patientDetails.patient_gender}</b>  </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="uhid">
                                <p>UHID No:......................................................................</p>
                            </div>
                            <div class="ipd">
                                <p>IP No:.......................................................................</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="namesurgeon">
                                <p>Name of Surgeon: <b>{doctors}</b>  </p>
                            </div>
                        </div>
                    </div>
                    <div class="line1">
                        <div class="sonof">
                            <p>
                                Advice on IV Fluids :
                                ............................................................................
                            </p>
                        </div>
                    </div>

                    <div class="line1">
                        <div class="sonof">
                            <p>
                                Medication to be Administered :
                                ............................................................................
                            </p>
                        </div>
                    </div>
                    <div class="line1">
                        <div class="sonof">
                            <p>
                                Care of Wound :
                                ............................................................................
                            </p>
                        </div>
                    </div>
                    <div class="line1">
                        <div class="sonof">
                            <p>
                                Nursing Care :
                                ............................................................................
                            </p>
                        </div>
                    </div>
                    <div class="line1">
                        <div class="sonof">
                            <p>
                                Complication to be observed (if any) :
                                ............................................................................
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint}>
                    Print Form
                </button>
            </div>
        </div>

    );
};


export default Post_plan;