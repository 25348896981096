
// export default FullLayout;
import { Outlet, Navigate } from "react-router-dom"; // Import Navigate
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import { UserContextProvider } from "../context/Usercontext";
import { DashboardContextProvider } from "../context/DashboardContext";
import { PatientContextProvider } from "../context/PatientsContext";
import { SelectDoctorProvider } from "../context/DoctorSelect";
import { AttendantProvider } from "../context/AttendentContext";
const FullLayout = () => {
  const authToken = localStorage.getItem("token");


  // Check if authToken exists
  if (!authToken) {
    // If authToken does not exist, navigate to the login page
    return <Navigate to="/login" />;
  }

  // If authToken exists, render the Outlet (nested routes)
  return (
    <UserContextProvider>
      <DashboardContextProvider>
      <PatientContextProvider>
        <SelectDoctorProvider>
           <AttendantProvider>

        <main>
          <div className="pageWrapper d-lg-flex">
            {/********Sidebar**********/}
            <aside className="sidebarArea shadow" id="sidebarArea">
              <Sidebar />
            </aside>
            {/********Content Area**********/}

            <div className="contentArea">
              {/********header**********/}
              <Header />
              {/********Middle Content**********/}
              <Container className="p-4 wrapper" fluid>

                <Outlet />
              </Container>
            </div>
          </div>
        </main>
           </AttendantProvider>
        </SelectDoctorProvider>
      </PatientContextProvider>
      </DashboardContextProvider>
    </UserContextProvider>
  );
};

export default FullLayout;
