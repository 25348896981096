import React, { useContext, useState,useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import axios from 'axios';
import { AttendantContext } from '../../context/AttendentContext';
const Attendant = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
   const {attendant,setAttendant}= useContext(AttendantContext);
  const [aid, setAid] = useState("");
  const [attendantData, setAttendantData] = useState({
    name: '',
    address: '',
    occupation: '',
    telephone: '',
    relation_with_patient: '',
    patient_id: '',
    signature: '',
  });

  const onChangeHandlers = (e) => {
    const { name, value } = e.target;
    setAttendantData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${baseurl}/attendant/addAttendant`, attendantData);
      setAid(response.data.attendant.id);
      // document.getElementById('generatedId').innerHTML = response.data.attendant.id;
    //  localStorage.setItem('attendant', JSON.stringify(response.data.attendant));
      alert('Attendant data added successfully');
      setAttendantData({
        name: '',
        address: '',
        occupation: '',
        telephone: '',
        relation_with_patient: '',
        patient_id: '',
        signature: '',
      });
      setDisable(false);
    } catch (error) {
      console.log('Error submitting attendant data:', error);
    }
  };


  const[disable,setDisable]=useState(true);
  const [hid, setHid] = useState("");
  useEffect(() => {
    const HID = localStorage.getItem("HID");
    setHid(HID);
  }, []);
  const select=async(aid)=>{
    try {
      const response = await axios.get(`${baseurl}/attendant/getattendants/${hid}/${aid}?searchType=id`);
      if (response.data.attendant) {

          const attendant = response.data.attendant;
          const attendantId = response.data.attendant.id;
          setAttendant(attendant);
          localStorage.setItem('attendantId', JSON.stringify(attendantId));

      } else {

          alert("Attendant not found")

      }
  } catch (error) {
    console.log(error)
      // alert(error.response.data.msg);
  }
  }
  console.log(attendant);
  return (
    <div className="modal-dialog mb-0 modal-xl">
      <div className="modal-content ">
        <div className="modal-header">
          <h1 className="modal-title fs-5 text-primary  p-3 rounded text-opacity-75" id="staticBackdropLabel">
            Please Fill the details of Attendant
          </h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body  p-4 bg-body-tertiary rounded  m-4">
          <Form onSubmit={handleSubmit} className="row">
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="name">Name / नाम</Label>
              <Input
                type="text"
                id="name"
                placeholder="Enter name"
                name="name"
                required
                value={attendantData.name}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="address">Address / पता</Label>
              <Input
                type="text"
                id="address"
                required
                placeholder="Enter address"
                name="address"
                value={attendantData.address}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="occupation">Occupation / व्यवसाय</Label>
              <Input
                type="text"
                id="occupation"
                required
                placeholder="Enter occupation"
                name="occupation"
                value={attendantData.occupation}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="telephone">Telephone / दूरभाष</Label>
              <Input
                type="tel"
                id="telephone"
                required
                placeholder="Enter telephone"
                name="telephone"
                value={attendantData.telephone}
                onChange={onChangeHandlers}
                pattern="[0-9]{10}"
                title="Please enter a valid 10-digit phone number"
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="relation">Relation with Patients (if any) / संबंध</Label>
              <Input
                type="text"
                id="relation"
                required
                placeholder="Enter relation"
                name="relation_with_patient"
                value={attendantData.relation_with_patient}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="patient_id">Patients ID</Label>
              <Input
                type="text"
                id="patient_id"
                required
                placeholder="Enter Patient ID"
                name="patient_id"
                value={attendantData.patient_id}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="mb-3 col-6">
              <Label htmlFor="signature">Digital Signature</Label>
              <Input
                type="text"
                id="signature"
                placeholder="Sign Please"
                name="signature"
                value={attendantData.signature}
                onChange={onChangeHandlers}
              />
            </FormGroup>
            <FormGroup className="col-6"></FormGroup>
            <Button type="submit" color="primary" className="btn m-3 col-2">
              Submit
            </Button>
          </Form>
          <div className=" row gap-1 mt-2 p-2">
              <div className="col-3 border border-info rounded-3  ">
                <h5 className="text d-flex mt-1">ID : <b id="generatedId">{aid}</b></h5>
              </div>
              <div className="col">
                <button className="btn btn-primary"   data-bs-dismiss="modal" onClick={()=>select(aid)} disabled={disable}> Select </button>
              </div>
              
            </div>
         
        </div>
      </div>
    </div>
  );
};

export default Attendant;
