import { Col, Row, Table, Card, CardTitle, CardBody, Button } from "reactstrap";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import TopCards from "../components/dashboard/TopCards";
import { useContext, useEffect, useState } from "react";
import {DashboardContext } from "../context/DashboardContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import AddDoctorsModal from "./Adddoctors";
import { UserContext } from "../context/Usercontext";
import { Link } from "react-router-dom";
const Starter = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const[doctor, setDoctor]= useState("");
  const { user, setUser, Id, setId } = useContext(UserContext);
  const { totalPatients, setTotalPatients, report, setReport } = useContext(DashboardContext);
 
 //const user_id= user.hospital.user_id;
 const updateUser = async (user_id) => {

    try {
      const response = await axios.get(`${baseurl}/hospital/${user_id}`);
      // console.log(response.data);
      if (response.status === 200) {
        setData(response.data);
        setUser(response.data);
        setId(id);
      }
    } catch (error) {
      Navigate("/login");
      // alert(error.message)
    }
  };
  const allpatients = async (HID) => {
    try {
      const response = await axios.get(`${baseurl}/patient/getAllPatientsByHid/${HID}`);

      //  console.log(response.data.patients);
      setTotalPatients(response.data.patients);
    } catch (error) {
      console.error("Error while Fetching Patients Details:", error);
      // alert("Error while Fetching Patients Details");
      setTotalPatients("");
    }
  }

  const allreport = async (HID) => {
    try {
      const response = await axios.get(`${baseurl}/formdetails/formByHospitalId/${HID}`);
      //  console.log(response.data.formdetails);
      setReport(response.data.formDetails);
    } catch (error) {
      console.error("Error while Fetching Reports Details:", error);
      // alert("Error while Fetching Reports Details");
    }
  }
  const[alert,setAlert]=useState(false);
 const navigate=useNavigate();
  const alldoctor = async (HID) => {
    try {
      const res = await axios.get(`${baseurl}/doctor/doctors/hospitals/${HID}`);
      // console.log(res.data.length)
      // if(res.data.length==0){
      //   toggleModal();
      // }else{
        
      // }
      if(res.data.length==0){
        // // alert("Please add doctors first");
        setAlert(true);
        // navigate("/adddoctor");
        toggleModal()
      }
      setDoctor(res.data.length)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const hid = localStorage.getItem("HID");
    // console.log(hid);
    const user_id= localStorage.getItem('id');
    updateUser(user_id);
    allpatients(hid);
    allreport(hid);
    alldoctor(hid);
  }, [])

  setInterval(() => {
    setTime(new Date().toLocaleTimeString());
  }, 1000)

  const [time, setTime] = useState("")

  const [showModal, setShowModal] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
      setShowModal(!showModal);
    
  };

  return (
    <div className="col-sm-11">
      {/***Top Cards***/}
      <div>
        {alert === true ? (
          <>
          <AddDoctorsModal showModal={showModal} toggleModal={toggleModal} />
          <div className="bg-light-warning rounded p-3 d-flex justify-content-between m-1" >
            <h4 >Please Add Doctors </h4>
            <Link className=" btn btn-outline-primary" to="/doctors" >Add Doctor</Link>
          </div>
          </>
           
        ) : null}
      </div>
      <Row>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-success text-success"
            title="Total Patients Registred"
            subtitle=" Patients Registred"
            earning={totalPatients.length}
            icon="bi bi-person"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-success text-success"
            title="Refunds"
            subtitle="Total Printed Form "
            earning={report.length}
            icon="bi bi-journal-text"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-warning text-warning"
            title="New Project"
            subtitle="Total Doctors"
            earning={doctor}
            icon="bi-ui-checks"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-info text-into "
            title="Sales"
            subtitle="Time "
            earning={time}
            icon="bi bi-clock"
          />
        </Col>
      </Row>
      {/***Sales & Feed***/}
      <Row>
        <Col sm="6" lg="6" xl="7" xxl="8">
          <SalesChart />
        </Col>
        <Col sm="6" lg="6" xl="5" xxl="4">
          <Feeds />
        </Col>
      </Row>
      {/* <AddDoctorsModal showModal={showModal} toggleModal={toggleModal} /> */}
    </div>
  );
};

export default Starter;
