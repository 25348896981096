
import "./CarePlan.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from '../../FormHeader';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import { patientContext } from '../../../context/PatientsContext';
import { SelectDoctorContext } from '../../../context/DoctorSelect';
import { UserContext } from "../../../context/Usercontext";
const Care_plan = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const { currentDoctor } = useContext(SelectDoctorContext)
    const{user}= useContext(UserContext)
    const{patient}=useContext(patientContext);
    console.log(patient)
    const { uid } = useParams();
    // console.log(currentDoctor);

 
    const [pdfData, setPdfData] = useState(null);
    const generatePDF = async () => {
        try {
            const input = document.getElementById('divToPrint');
            if (!input) {
                throw new Error('Element with id "divToPrint" not found.');
            }
    
            const canvas = await html2canvas(input);
            if (!canvas) {
                throw new Error('Canvas not generated.');
            }
    
            const imgData = canvas.toDataURL('image/jpeg');
            if (!imgData) {
                throw new Error('Image data not generated.');
            }
    
            const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
    
            // Convert PDF to Blob
            const blob = pdf.output('blob');
    
            // Set the Blob object to state
            setPdfData(blob);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    const [previewDisabled, setPreviewDisabled] = useState(true);
    const submitForm = async (e) => {
        e.preventDefault();
        await generatePDF();
        let PID = patient.id;
        try {
            if (currentDoctor.name === "" || PID === "") {
                alert("Please select patient and doctor");
                return;
            }
            
            // Create FormData object
            const formData = new FormData();
            formData.append('doctor_id', currentDoctor.id);
            formData.append('patient_id', PID);
            formData.append('date', new Date().toLocaleDateString());
            formData.append('hospital_id', user.hospital.id);
            formData.append('unique_master_form_id', uid);
            formData.append('image_link', pdfData, 'download.pdf');
    
            const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPreviewDisabled(false);
            alert(res.data.msg);
        } catch (error) {
            console.log(error);
            // alert(error.response.data.error);
        }
    };
    
  



    //  ==================== Doctor list =================

    return (
        <div>
            <FormNavbar id="navbar" className="navbar" >
            </FormNavbar>
            <div id="divToPrint" className="m-4 p-4" >
                <div class=" row"  >
                    <div className="col-sm-8">
                        <FormHeader></FormHeader>
                        <div className="header">
                            <div
                                className="modal fade"
                                id="exampleModal1"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <Attendant></Attendant>

                            </div>
                            <div
                                className="modal fade"
                                id="exampleModal2"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <AttendantSearch></AttendantSearch>
                                {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                                <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                            </div> */}
                            </div>

                            <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <Patients >
                                </Patients>
                                {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                                    <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                                </div> */}
                            </div>
                            <div
                                class="modal fade"
                                id="registrationModal"
                                tabIndex="-1"
                                aria-labelledby="registrationModalLabel"
                                aria-hidden="true"
                            >

                                <PatientRegister></PatientRegister>
                            </div>
                        </div>

                    </div>
                    <div class=" col-sm-4">
                        <div class="formboxdetails">
                            <div class="line2">

                                <p> Patient Name : <b>{patient.patient_name}</b></p>

                            </div>
                            <div class="line2">
                                <div class="agesex">
                                    <p>Age : <b>{patient.patient_age}</b>.</p>
                                </div>
                                <div class="agesex">
                                    <p>Sex : <b>{patient.patient_gender}</b></p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">
                                    <p>Bed No. :......................</p>
                                </div>
                                <div class="bed">
                                    <p>IPD No. :.......................</p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">

                                    <p> Consultant :...........................................................</p>
                                </div>
                            </div>
                            <div class="line2">
                                <div class="bed">
                                    <p> Reg. No. :..............................................................</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-container ">
                    <div class="form-heading">
                        <h3>CARE PLAN</h3>
                    </div>
                    <div class="form-details container">
                        <div class="row">
                            <div class="chief col">
                                <p>
                                    Status at the Beginning Of The Treatment :................................
                                </p>
                            </div>
                            <div class="line1 col">
                                <div class="provisional">
                                    <p>Provisional Diagnosis: ......................................................</p>
                                </div>
                            </div>
                        </div>

                        <div class="Preventative">
                            <p>Preventative Care : Explaind / Educational Learlet <br />
                                (Please Write the Explaind Part in Brief)
                            </p>
                        </div>


                        <div class="signature mt-2">
                            <p>Progress Chart 2 :-</p>
                        </div>

                        <div class="nextsignature">
                            <p class="border">
                                <table>
                                    <tr>
                                        <th>Sickness</th>
                                        <th>DAY 1</th>
                                        <th>DAY 2</th>
                                        <th>DAY 3</th>
                                        <th>DAY 4</th>
                                        <th>DAY 5</th>
                                        <th>DAY 6</th>
                                        <th>DAY 7</th>
                                    </tr>
                                    <tr>
                                        <td>Severe</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Moderate</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Mild</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>WNL</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                            </p>
                        </div>
                        <div class="line1">
                            <div class="dets">
                                <p>
                                    Rehabilitative Remark:
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    Anticipated Complications:
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    Goals Of Treatment -
                                </p>
                            </div>
                            <div class="ambulatory">
                                <p>
                                    Curative  <input type="checkbox"></input>
                                </p>
                            </div>
                            <div class="ambulatory">
                                <p>
                                    Preventive  <input type="checkbox"></input>
                                </p>
                            </div>
                            <div class="ambulatory">
                                <p>
                                    Rehabilitative  <input type="checkbox"></input>
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    Consultations Name & Signature
                                </p>
                            </div>
                        </div>
                        <hr class="seprator" />
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    Note : If the Patient Stay is more than 7 days , Please fill the Revised Care Plan.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3"  onClick={submitForm} disabled={! currentDoctor.name|| !patient.id}>
                    Submit
                </button>
                <button className="btn btn-primary" onClick={generatePDF} disabled={previewDisabled}>
                    Print Form
                </button>
            </div>

        </div>
    );
};

export default Care_plan;
