import React, { useContext, useEffect, useState } from 'react'
import "./Form.css"
import axios from 'axios';
import { SelectDoctorContext } from '../../context/DoctorSelect';
const FormNavbar = ({ doctor1 , doctorID}) => {
  const { currentDoctor, setCurrentDoctor } = useContext(SelectDoctorContext);

  const baseurl = process.env.REACT_APP_BASE_URL;
  const [doctors, setDoctors] = useState([]);
  
  useEffect(() => {
    const hid= localStorage.getItem('HID');
    getdoc(hid);
  }, []);


  // this function is called to get all doctors in the particuler hospital 

  const getdoc = async (hid) => {
    try {
      const id = localStorage.getItem('id');
      const token = localStorage.getItem('token');
      // Make sure ID and token are available
      if (id && token) {
        const response = await axios.get(`${baseurl}/doctor/doctors/hospitals/${hid}`);
        setDoctors(response.data);
        // setCurrentDoctor(response.data);  
        // console.log(response.data);
        //console.log("call todoctor");
      }
    } catch (error) {
      console.log(error);
    }
  };

// this is the function this will set value of doctor when we select 
  const handleDoctorSelect = (doctorName, doctor_id) => {
    // doctor1(doctorName);
    // doctorID(doctor_id);
    // setCurrentDoctor(doctorName);
    
    setCurrentDoctor({ name: doctorName, id: doctor_id });

  }

  const [showModal, setShowModal] = useState(false);

  // Function to toggle the modal visibility
  const toggleModal = () => {
      setShowModal(!showModal);
      
  };

//================================================================
  return (
    <div className=" bg-body-tertiary">
      <nav className="navbar navbar-expand-lg container">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="patient row ">
          
              <div className="col-md ">
                <button
                  type="button"
                  className="btn btn-outline-primary modal-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Existing Patient
                </button>
                
              </div>
              <div className="col-md ">
                <button
                  type="button"
                  className="btn btn-outline-primary  modal-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#registrationModal"
                  onClick={toggleModal}
                >
                  New Patient
                </button>
              </div>
              <div className="col-md ">
                <button
                  type="button"
                  className="btn btn-outline-primary  modal-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                >
                  New  Attendant
                </button>
              </div>
              <div className="col-md ">
                <button
                  type="button"
                  className="btn btn-outline-primary  modal-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  Existing Attendant
                </button>
              </div>

              <div className="col-md ">
                <div className="btn-group ">
                  <button className="btn btn-outline-primary dropdown-toggle modal-btn doctor-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Doctors
                  </button>
                  <ul className="dropdown-menu">
                    {doctors.map((doctor) => (
                      <li className="dropdown-item" key={doctor.id} value={doctor.name} onClick={() => handleDoctorSelect(doctor.name ,doctor.id)}>
                        <b>{doctor.name}</b>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>


            </div>

          </div>
        </div>
      </nav>
    </div>
  )
}

export default FormNavbar