import React, { useState } from "react";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import backgroundImg from "../assets/images/bg/forgetpassword.png";
import axios from "axios";

const ForgotPassword = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setIsValidEmail(validateEmail(value));
  };

  const handleSubmit = async(event) => {
    event.preventDefault(); // Prevent form submission
    if (isValidEmail) {
     const res = await axios.post(`${baseurl}/user/forgot-password`, { "email": email});
     if(res.status===200){
       alert("Forget password link has been sent successfully to your registred email ", email);
       console.log("Form submitted with email:", email);
     }
    }
  };

  const validateEmail = (inputEmail) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };
 
  
  return (
    <div>
      <div className="container d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <div className="row align-self-center bg-light-subtle rounded" style={{ width: "80%", alignItems: "center" }}>
          <div className="col-sm-6 p-3 registration rounded">
            <img className="img-fluid rounded" src={backgroundImg} style={{ width: "100%" }} alt="" />
          </div>
          <div className="col-sm-6 p-3 rounded">
            <h4 className="text-center p-3 login_heading">Forgot Password</h4>
            {/* Form for password reset */}
            <Form className="p-3" onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder=""
                  invalid={!isValidEmail} // Set invalid state if email is invalid
                  required
                />
                {/* Display validation feedback if email is invalid */}
                <FormFeedback>
                   Please enter a valid email address.
                </FormFeedback>
              </FormGroup>
              <button type="submit" className="btn btn-primary">
                Forgot Password
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
