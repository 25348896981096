import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { patientContext } from '../../context/PatientsContext';


const Patients = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const{patient, setPatient}= useContext(patientContext)
    const [hid, setHid] = useState("");

    useEffect(() => {
        const HID = localStorage.getItem("HID");
        setHid(HID);
    }, []);

    const [patientDetail, setPatientDetail] = useState("");
    const [id, setId] = useState("");

    const handleChange = (e) => {
        setId(e.target.value);
    };

    const handleSearch = async (event) => {
        event.preventDefault();
        try {
            localStorage.removeItem('patientDetails');
            const formattedInput = id.replace(/\s/g, '');
            let response;
            if (formattedInput.length === 10 && !isNaN(formattedInput)) {
                // If input is a 10-digit number, assume it's a phone number search
                response = await axios.get(`${baseurl}/patient/getpatients/${hid}/${formattedInput}?searchType=patient_phone`);
            } else {
                // Otherwise, treat it as an formattedInput search
                response = await axios.get(`${baseurl}/patient/getpatients/${hid}/${formattedInput}?searchType=id`);
            }

            if (response.data.patient) {
                const patientData = response.data;
                console.log(patientData);
                setPatient(patientData.patient);
                setPatientDetail(patientData.patient);
                localStorage.setItem('patientDetails', JSON.stringify(patientData));
                
            } else {
                alert('Patient not found');
            }
        } catch (error) {
            console.log(error.response.data.msg)
            setPatientDetail("");
            alert(error.response.data.msg);

        }
    };

const clearPatient=()=>{
    setPatient("");
    setPatientDetail("");
}
    return (
        <div className="modal-dialog modal-xl mb-0">
            {/* <p>{patient}</p> */}
            <div className="modal-content">
                <div className="modal-header">
                    {/* <h1 className="modal-title fs-5 text-primary  p-3 rounded text-opacity-75" id="exampleModalLabel">
                        Search For Patients
                    </h1> */}
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body p-4 bg-body-tertiary rounded  m-4">
                    <div className="row mt-3 Patients">
                        <div className="col-md-5 bg-light rounded">
                            <h4>Search Patients</h4>
                            <hr />
                            <form onSubmit={handleSearch}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Search Patients
                                        </label>
                                        <input
                                            type="text"
                                            className="col-3 form-control"
                                            placeholder="Enter ID / Phone "
                                            id="searchInput"
                                            name="searchInput"
                                            value={id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-3 mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-3"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-7 bg-light rounded">
                            <h4>Patients Details</h4>
                            <hr />
                            <div className="details row">
                                <div className="name col-6">
                                    <p>
                                        Name: &nbsp; <b id='pname'>{patientDetail.patient_name}</b>
                                    </p>
                                    <p>
                                        Patient ID: &nbsp; <b id='pid'>{patientDetail.id}</b>
                                    </p>
                                    <p>
                                        Phone: &nbsp; <b id='pphone'>{patientDetail.patient_phone}</b>
                                    </p>
                                </div>
                                <div className="name col-6">
                                    <p>
                                        Age: &nbsp; <b id='page'>{patientDetail.patient_age}</b>
                                    </p>
                                    <p>
                                        Address: &nbsp; <b id='paddress'>{patientDetail.patient_address }  {patientDetail.city_name} {patientDetail.state_name }</b>
                                    </p>
                                    <button className='btn btn-outline-primary mt-2' onClick={()=>clearPatient()}>Clear Patient </button>
                                </div>
                            </div>
                                <div className='col-3 mt-2'>
                                </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </div>
    );
}

export default Patients;
