
import "./Prechecklist.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";

const Pre_checklist = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [id, setid] = useState({ id: "" })
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [attendantId, setAttendantId] = useState('');
 
    const [patientDetails, setPatientDetails] = useState({
        id: 6,
        patient_name: "..............",
        patient_age: "..............",
        patient_gender: "............",
        patient_phone: ".............",
        patient_address: "...........",
        patient_state: ".............",
        patient_city: ".............",
        patient_pincode: "...........",
    });
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })
    
    const handlechageDoctor = (data) => {
        // console.log(data);
        setDoctors(data);
        // setSelectedDoctor(data);
        // handleDoctorSelect();
    }

    const [doctorsID, setDoctorsID] = useState("");
    let idD = "";
    const getDoctor_id = (id) => {
        setDoctorsID(id);
        console.log(id);
        idD = id;
        // setFormData.doctor_id=id;
        //console.log(doctorsID);
    }
   
    //==========  this fuction work when we select a patient
    const Select = () => {
        removeAttendant();
        const savedPatientDetails = localStorage.getItem('patientDetails');
        if (savedPatientDetails) {
            const pData = JSON.parse(savedPatientDetails);
            setPatientDetails(pData.patient);
            console.log(pData.patient); // Add this line to check if data is retrieved correctly
        }
    };


    const removePatientData = () => {
        setPatientDetails({
            id: "",
            patient_name: "",
            patient_age: "",
            patient_gender: "",
            patient_phone: "",
            patient_address: "",
            patient_state: "",
            patient_city: "",
            patient_pincode: "",
        });
    }
    // ================ select attendant =================



    const select = async () => {
        removePatientData();
        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
            const attendantId = localStorage.getItem('attendantId');
            const response = await axios.get(`${baseurl}/attendant/${attendantId}`);
            setAttendantData(response.data.attendant);
            console.log(response.data.attendant);

        } catch (error) {
            alert(error.message);
        }

    }

    const removeAttendant = () => {
        setAttendantData({
            name: "",
            address: "",
            occupation: "",
            telephone: "",
            relation_with_patient: "",
            patient_id: "",
            signature: "",
        })
    }
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    //  ==================== Doctor list =================



    const PatientAddress = patientDetails.patient_address + " " + patientDetails.patient_pincode + " " + patientDetails.patient_city;
    const AttendantAddress = attendant.address;

    return (
        <div>
            <FormNavbar id="navbar" className="navbar" doctor1={handlechageDoctor} doctorID={getDoctor_id}>
            </FormNavbar>
            <div className="container">

                <div className="row">
                    <FormHeader></FormHeader>
                </div>

                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>


                <hr class="seprator" />
                <div class="form-container">
                    <div class="form-heading">
                        <h3>PRE OPERATIVE CHECKLIST</h3>
                    </div>
                    <div class="formbutton">
                        <button type="button">Patient Label</button>
                    </div>
                    <div class="form-details">
                        <div class="line1">
                            <div class="pac">
                                <p> </p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p>YES</p>
                            </div>
                            <div class="pac1">
                                <p>NO</p>
                            </div>
                            <div class="pac1">
                                <p>NA</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>PAC </p>
                            </div>
                            <div class="pac">
                                <p>Date :</p>
                            </div>
                            <div class="pac1">
                                <p> <input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p> <input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Physician Clearance For surgery :</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="sonof">
                                <p>
                                    Vital Signs :
                                </p>
                            </div>
                            <div class="sonof">
                                <p>
                                    B.P :......................
                                </p>
                            </div>
                            <div class="sonof">
                                <p>
                                    Temp :...........
                                </p>
                            </div>
                            <div class="sonof">
                                <p>
                                    Pulse :...........
                                </p>
                            </div>
                            <div class="sonof">
                                <p>
                                    Resp :..........
                                </p>
                            </div>
                        </div>

                        <div class="line1">
                            <div class="sonof1">
                                <p> AM Care
                                </p>
                            </div>
                            <div class="sonof1">
                                <p> Bath
                                </p>
                            </div>
                            <div class="sonof1">
                                <p> Shower
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="sonof1">
                                <p>
                                    Voided
                                </p>
                            </div>
                            <div class="sonof1">
                                <p>
                                    Amt.
                                </p>
                            </div>
                            <div class="sonof1">
                                <p>
                                    Time
                                </p>
                            </div>
                            <div class="sonof1">
                                <p>
                                    On Catheter
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="sonof1">
                                <p>
                                    Enema
                                </p>
                            </div>
                            <div class="sonof1">
                                <p>
                                    Bowel Wash
                                </p>
                            </div>
                            <div class="sonof1">
                                <p>
                                    Laxaives to Dress
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="medi">
                                <p>
                                    Pre-op Medication given.............
                                </p>
                            </div>
                            <div class="madi">
                                <p>
                                    Time.............
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Skin Prep :</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="medi">
                                <p>
                                    Identity of site :
                                </p>
                            </div>
                            <div class="medi">
                                <p>
                                    Right.............
                                </p>
                            </div>
                            <div class="madi">
                                <p>
                                    Left.............
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="Proposed">
                                <p>
                                    Proposed Proedure.............
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Identity Band  :</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Allergic Band  :</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Denture/partial Plate/Upper/Lower/Fixed/Removable.................</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>contact Lenses / Glasses...............</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> Jewellery/Yellow/Glasses.............</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> Special Consent for Amputation.............</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> Ready PCR/WB.............Unit FFP..........Unit</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> CBC</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> Culture</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p> ECG</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>PFT</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Chest X-RAY</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>MRI</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>CT Scan</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>USG</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Echogram</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Angrogram</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>X-RAY Regional</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Others</p>
                            </div>
                            <div class="pac">
                                <p></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                            <div class="pac1">
                                <p><input type="checkbox" /></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Signature o S/N</p>
                            </div>
                            <div class="pac">
                                <p>Signature of S/N Receiving in OT</p>
                            </div>
                            <div class="pac">
                                <p>Checklist</p>
                            </div>
                            <div class="pac">
                                <p>Pre</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Date :</p>
                            </div>
                            <div class="pac">
                                <p>Signature of S/N Receiving Ward</p>
                            </div>
                            <div class="pac">
                                <p>Signature of S/N</p>
                            </div>
                            <div class="pac">
                                <p>Post</p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="pac">
                                <p>Time : </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Pre_checklist;