import { createContext,  useState} from "react";

export const DashboardContext = createContext(null);

export const DashboardContextProvider = (props) => {
 
  const [totalPatients, setTotalPatients] = useState({})
  const[report, setReport]= useState({});

  return (
    <DashboardContext.Provider value={{ totalPatients, setTotalPatients, report, setReport }}>
      {props.children}
    </DashboardContext.Provider>
  );
};