import React, { useContext } from 'react'
import { useEffect, useState } from 'react';
import "./Surgicaloperation.css";
import FormNavbar from '../FormNavbar';
import axios from 'axios';
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from '../../FormHeader';
import { patientContext } from '../../../context/PatientsContext';
import { SelectDoctorContext } from '../../../context/DoctorSelect';
import { UserContext } from '../../../context/Usercontext';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";

const Surgical_operation = (doctor1, doctorID) => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const{patient}=useContext(patientContext);
    const{user}= useContext(UserContext);
    const {currentDoctor}=useContext(SelectDoctorContext);
    const [id, setid] = useState({ id: "" })
    const[hid,setHid]=useState("");
        useEffect(()=>{
            const id = localStorage.getItem('HID');
          setHid(id);
        })
    //  attendant details 
    const [attendant, setAttendantData] = useState({
        name: "",
        address: "",
        occupation: "",
        telephone: "",
        relation_with_patient: "",
        patient_id: "",
        signature: "",
    })
    //this use effect call when the page render is finished
  
 

 


    const select = async () => {

        const savedAttendantDetails = localStorage.getItem('attendant');
        if (savedAttendantDetails) {
            const aData = JSON.parse(savedAttendantDetails);
            setid(aData.id);
        }
        getAttendant();
        // console.log(id);

    }

    const getAttendant = async () => {
        try {
          const attendantId = localStorage.getItem('attendantId');
          const response = await axios.get(`${baseurl}/attendant/getattendants/${hid}/${attendantId}?searchType=id`);
          setAttendantData(response.data.attendant);
          console.log(response.data.attendant);
          setPreviewDisabled(false);
        } catch (error) {
          alert(error.message);
        }
    
      }
   
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    const { uid } = useParams();
    //  ==================== Doctor list =================
    const [pdfData, setPdfData] = useState(null);

    const generatePDF = async () => {
        try {
            const input = document.getElementById('divToPrint');
            if (!input) {
                throw new Error('Element with id "divToPrint" not found.');
            }
    
            const canvas = await html2canvas(input);
            if (!canvas) {
                throw new Error('Canvas not generated.');
            }
    
            const imgData = canvas.toDataURL('image/jpeg');
            if (!imgData) {
                throw new Error('Image data not generated.');
            }
    
            const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
            //  pdf.save();
            // Convert PDF to Blob
            const blob = pdf.output('blob');
    
            // Set the Blob object to state
            setPdfData(blob);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    const [previewDisabled, setPreviewDisabled] = useState(true);
    const submitForm = async (e) => {
        // await generatePDF();
        e.preventDefault();
        let PID = patient.id;
        try {
            if (currentDoctor.name === "" || PID === "") {
                alert("Please select patient and doctor");
                return;
            }
            await generatePDF();

            // Create FormData object
            const formData = new FormData();
            formData.append('doctor_id', currentDoctor.id);
            formData.append('patient_id', PID);
            formData.append('date', new Date().toLocaleDateString());
            formData.append('hospital_id', user.hospital.id);
            formData.append('unique_master_form_id', uid);
            formData.append('image_link', pdfData, 'download.pdf');
    
            const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPreviewDisabled(false);
            alert(res.data.msg);
        } catch (error) {
            console.log(error);
            // alert(error.response.data.error);
        }
    };
    
  




    const PatientAddress = patient.patient_address + " " + patient.patient_pincode + " " + patient.patient_city;
    const AttendantAddress = attendant.address;
    return (
        <div>
            <FormNavbar id="navbar" className="navbar">
            </FormNavbar>
            <div className="main-header container p-4" id="divToPrint">
                <div className="row">

                    <FormHeader></FormHeader>
                </div>
                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " data-bs-dismiss="modal"
                                aria-label="Close" onClick={select}>Select This Attendant</button>
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " data-bs-dismiss="modal"
                                aria-label="Close" onClick={Select}>Select This Patient</button>
                        </div> */}
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>
                {/* <hr class="seprator" /> */}
                <div class="form-container">
                    <div class="form-heading">
                        <h5>
                            CONSENT FORM FOR SURGICAL OPERATION AND / OR DIAGNOSTIC /THERAPEUTIC
                            PROCEDURE
                        </h5>
                    </div>
                    <div class=" row">
                        <div class=" col-sm-4">
                            <p>C.R No .................</p>
                        </div>
                        <div class="col-sm-4">
                            <p>UHID NO : .............</p>
                        </div>
                        <div class="col-sm-4">
                            <p>Date : <b>{new Date().toDateString()}</b></p>
                        </div>
                    </div>
                    <div class="details2">
                        <div class="name">
                            <p>Name : <b>{patient.patient_name}</b></p>
                        </div>
                        <div class="sex">
                            <p>Sex : <b>{patient.patient_gender}</b></p>
                        </div>
                        <div class="age">
                            <p>Age : <b>{patient.patient_age}</b></p>
                        </div>
                    </div>

                    <div class="son">
                        <div class="sonof">
                            <p>S/o, D/o, W/o : ...................</p>
                        </div>
                    </div>
                    <div class="heading">
                        <h6 class="h6">Authorization For Surgical Operation And / or Diagnostic / Therapeutic Procedure
                        </h6>
                    </div>
                    <div class="para">
                        <ol>
                            <li> I hereby authorize the above hospital and its staff to perform upon the above patient the following
                                surgical Operation and/or diagnostic/therapeutic procdure

                            </li>
                            <li>
                                It has been explained to me that, during the course of thr operation / procedure unforeseen
                                condition may be revealed or encountered which necessitate surgical or there emergency procedures in
                                addition to or different for those contemplated at the time to initial diagnosis, I therefore,
                                further authorize above designated staff to perform such additional surgical or other procedures as
                                they deem necessary or desirable.

                            </li>
                            <li>
                                I further consent to the administration of drugs infusions, blood product transfusions or any other
                                treatment or procedure dimmed necessary.

                            </li>
                            <li>
                                The nature and purpose of the operation and/ or procedures, the necessity thereof, the possible
                                alternative methods. treatment, prognosis, the risks involved and the possibility of complication in
                                the investigative procedures/ investigation and treatment of my condition/diagnosis have been fully
                                explained to me and I have understood the same

                            </li>
                            <li>
                                I have been given an opportunity to ask all any questions and I have also been given option to ask
                                for second opinion.


                            </li>
                            <li>
                                I acknowledge that no guarantee and promise has been made to me consuming the result of any
                                procedure/treatment.


                            </li>
                            <li>
                                I consent to the photographing or televising of the operation or procedures to be performed,
                                including appropriate portions of my body, for medical, scientific of educational purpose, provided
                                my identity is not revealed by pictures or by descriptive texts accompanying them.

                            </li>
                            <li>
                                I also give consent to the disposal by hospital authorities of any deceased tissued of parts there
                                of necessary to or removed during the course of operative procedure/treatment.
                            </li>

                        </ol>
                    </div>
                    <div class="certify">
                        <p>CERTIFY THAT THE STATEMENT MADE IN THE ABOVE CONSENT FORM HAS BEEN READ OVER AND EXPLINED TO ME IN MY MOTHER TONGUE AND I HAVE FULLY UNDERSTOOD THE IMPLICATIONS OF THE ABOVE CONSENT.
                        </p>
                    </div>
                    <div class="sign">
                        <div class="witness-name-sign">
                            <p>Name & Signature of the witness</p>
                            <p><b>{attendant.name}</b></p>
                            <p>--------------------------</p>
                        </div>
                        <div class="patients-sign">
                            <p>Signature of the patients /Parent/Gurdian</p>
                            <p>Thumb Impression</p>
                            <p>Name: <b>{attendant.name}</b></p>
                            <p>Relationship With Patients : <b> {attendant.relation_with_patient}</b></p>
                        </div>
                    </div>
                    <div class="cerify1">
                        <p> I CONFIRM THAT I HAVE EXPLANIED THAT NATURE AND EFFECTS OF THE OPERATION/

                            OCEDUE/THREATMENT TO THE PERSON WHO HAS SIGNED THE ABOVE CONSENT FORM
                        </p>
                    </div>
                    <div class="sign-anaes">
                        <p> Signature Of Anaesthetist</p>
                        <p> Name : <b>{currentDoctor.name}</b></p>
                    </div>
                </div>

            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" onClick={submitForm} >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint} disabled={previewDisabled}>
                    Print Form
                </button>
            </div>
        </div>
    );
};

export default Surgical_operation;
