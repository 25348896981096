import React from "react";
import Chart from "react-apexcharts";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

const Feeds = () => {
  const options = {
   
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: ['Today', 'Weakly', 'Monthly'],
    }
  };

  const series = [{
    data: [21, 22, 10]
  }];

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5"> Patients Registraion Data</CardTitle>
        <CardSubtitle className="mb-2 text-muted" tag="h6">
       Montly Report
        </CardSubtitle>
        <Chart options={options} series={series} type="bar" height={390} />
      </CardBody>
    </Card>
  );
};

export default Feeds;
