import "./Consent_for_treatment.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import { useParams } from "react-router-dom";
import FormHeader from "../../FormHeader";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { patientContext } from '../../../context/PatientsContext';
 import { SelectDoctorContext } from '../../../context/DoctorSelect';
 import { UserContext } from "../../../context/Usercontext";
 import { AttendantContext } from "../../../context/AttendentContext";
const Consent_for_treatment = () => {
  const { uid } = useParams();
  const { user } = useContext(UserContext);
  const {patient,setPatient}=useContext(patientContext);
  const { currentDoctor } = useContext(SelectDoctorContext);
  const {attendant}=useContext(AttendantContext);
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [id, setid] = useState("")
  const [hid, setHid] = useState("")


  //  attendant details 
  // const [attendant, setAttendantData] = useState({
  //   name: "",
  //   address: "",
  //   occupation: "",
  //   telephone: "",
  //   relation_with_patient: "",
  //   patient_id: "",
  //   signature: "",
  //   id: ""
  // })

  //this use effect call when the page render is finished   using this wea re getting the hospital info for header 
  const [HID, SETHID] = useState("");
  useEffect(() => {
    const id = localStorage.getItem('id');
    const HID = localStorage.getItem('HID');
    SETHID(HID);
    setHid(id);
    
  }, []);

 console.log(id);
 console.log(hid);
 console.log(user.hospital.id);


  // const select = async () => {
  
  //   getAttendant();
  //   // console.log(id);

  // }

  // const getAttendant = async () => {
  //   try {
  //     const attendantId = localStorage.getItem('attendantId');
  //     const response = await axios.get(`${baseurl}/attendant/getattendants/${HID}/${attendantId}?searchType=id`);
  //     setAttendantData(response.data.attendant);
  //     // console.log(response.data.attendant);
  //     setPatient("");
  //     setPreviewDisabled(false);
  //   } catch (error) {
  //     alert(error.message);
  //   }

  // }

  // ============== print form =============================

    const [pdfData, setPdfData] = useState(null);

    const generatePDF = async () => {
        try {
            const input = document.getElementById('divToPrint');
            if (!input) {
                throw new Error('Element with id "divToPrint" not found.');
            }
    
            const canvas = await html2canvas(input);
            if (!canvas) {
                throw new Error('Canvas not generated.');
            }
    
            const imgData = canvas.toDataURL('image/jpeg');
            if (!imgData) {
                throw new Error('Image data not generated.');
            }
    
            const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
            //  pdf.save();
            // Convert PDF to Blob
            const blob = pdf.output('blob');
    
            // Set the Blob object to state
            setPdfData(blob);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }

    };
    
    const [previewDisabled, setPreviewDisabled] = useState(true);

    const submitForm = async (e) => {
        // await generatePDF();
        e.preventDefault();
        let PID = patient.id;
        // console.log(PID);
        // console.log(attendant.Patient.id);
        if(PID===undefined){
          PID=attendant.Patient.id;
        }
        try {
            console.log(PID);
            if (currentDoctor.name === "" || PID === "") {
                alert("Please select patient and doctor");
                return;
            }
            await generatePDF();

            // Create FormData object
            const formData = new FormData();
            formData.append('doctor_id', currentDoctor.id);
            formData.append('patient_id', PID);
            formData.append('date', new Date().toLocaleDateString());
            formData.append('hospital_id',user.hospital.id);
            formData.append('unique_master_form_id', uid);
            formData.append('image_link', pdfData, 'download.pdf');
           
            const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPreviewDisabled(false);
            alert(res.data.msg);
        } catch (error) {
            console.log(error);
            // alert(error.response.data.error);
        }
    };
    
  


  const handlePrint=()=>{
  window.print();
  }
  //  ==================== Doctor list =================

 
  // const AttendantAddress = attendant.address;

  return (
    <div className="">
      <FormNavbar id="navbar" className="navbar">
      </FormNavbar>
      <div className="main-header container p-4" id="divToPrint" >
        <FormHeader></FormHeader>

        <div className="header">
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Attendant></Attendant>

          </div>
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <AttendantSearch></AttendantSearch>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " data-bs-dismiss="modal"
                aria-label="Close" onClick={getAttendant}>Select This Attendant</button>
            </div> */}
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Patients >
            </Patients>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " onClick={Select}
                data-bs-dismiss="modal"
                aria-label="Close">Select This Patient</button>
            </div> */}
          </div>
          <div
            class="modal fade mt-0"
            id="registrationModal"
            tabIndex="-1"
            aria-labelledby="registrationModalLabel"
            aria-hidden="true"
          >
            <PatientRegister>
            </PatientRegister>
          </div>
        </div>
        {/* <hr className="separato" /> */}
        <div className="form-container">
          <div className="english-content">
            <p className="consent-heading text-center">Consent for Treatment</p>
            <p className="consent-main-para dropdown ">
              I the undersigned authorize  &nbsp;<b id="doc-btn"> Dr. {currentDoctor.name} </b> &nbsp;
              {/* I the undersigned authorize  &nbsp;<b id="doc-btn"> Dr. {doctors} </b> &nbsp; */}
              and such another physician/surgeon as may be assigned by them the
              nurses, technician, assistant and other person employed in the
              centre of the treatment of my patient at <b>{user.hospital.name} </b>.
              I have been explained that during hospitalization my
              Patient will be administrated drugs either orally or intravenously
              or by any other route as is required and for caring type of
              diagnostic examination, I have been explained the risk associate
              with the drug administration. I.V. Infusion, blood transfusion etc.
              And the future prognosis and cause of disease which my patient is
              having, I voluntarily accept the risk. I have also been explained
              that the facilities better than here may be available at other
              places to deal with emergencies arising out of these situations or
              arising out of disease itself.
            </p>
          </div>
          <div className="ptients-sign">
            <p>Patients Signature / Thumb impression</p>
          </div>
          <div className="hindi-para">
            <p lang="hi" className="hind-consent-main-para hi">
              <br />
              मैं अधोहस्ताक्षर, डा०. <b id="doc-name"> {currentDoctor.name} </b> और इस अस्पताल द्वारा निर्धारित किसी
              भी अन्य डाक्टर एंव नर्स, टैक्नीषियन व अन्य सहायक को अपने मरीज का
              ईलाज <b> &nbsp;{user.hospital.name} &nbsp;</b>, <b>{user.hospital.address}</b> में कराने की स्वीकृति
              देता / देती हूँ।
              <br />
              मुझे बता दिया गया है कि ईलाज के दौरान मेरे मरीज को दवाईयाँ, नर्स
              द्वारा मुख में या किसी भी प्रकार से दी जा सकती हैं। मुझे दवाईयों
              द्वारा व खून चढानें द्वारा होने वाली परेषानियों व खतरों से आगाह कर
              दिया गया है। मैं अपनी इच्छा से इन खतरों का सामना करने के लिए तैयार
              हूँ। और हर तरह की जाँच भी कराने के लिए तैयार हूँ। मुझे बता दिया गया
              है कि इस अस्पताल से भी अच्छी सुविधाएँ और जगहों पर भी उपलब्ध हो सकती
              हैं। मुझे सभी जरूरी जानकारी उपलब्ध करा दी गई हैं व मेरे हर प्रष्न का
              उत्तर मिल गया है। मैं पूर्ण रूप से संतुष्ट हूँ।
              <br />
              Details of attendant/Patient admitted by
            </p>
          </div>
          <div className="details-of-attendant">
            <ul>
              <li>
                Name/
                <span lang="hi" className="hi">
                  नाम &nbsp;
                  <b className="PatientName">
                    {patient.patient_name} &nbsp; {attendant.name}
                  </b>
                </span>
              </li>
              <li>
                Address/
                <span lang="hi" className="hi">
                  पता &nbsp;<b className="Address">{attendant.address} &nbsp;{ patient.patient_address } &nbsp; {patient.state_name}  &nbsp; { patient.city_name}  &nbsp;  {patient.patient_pincode} </b>
                </span>
              </li>
              <li>
                Occupation/
                <span lang="hi" className="hi">
                  व्यवसाय &nbsp;
                  <b className="Occupation">{attendant.occupation}</b>
                </span>
              </li>
              <li>
                Telephone/
                <span lang="hi" className="hi">
                  दूरभाष &nbsp;
                  <b className="Phone">{patient.patient_phone} &nbsp; {attendant.telephone}</b>
                </span>
              </li>
              <li>
                Relation with Patients(if any )/
                <span lang="hi" className="hi">
                  संबंध &nbsp;<b className="Relation">{attendant.relation_with_patient}</b>
                </span>
              </li>
              <li className="sign ">
                <p>Signature / Thumb impression: - &nbsp; <b className="text-uppercase">{attendant.signature}</b></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="button">
        <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>

        <button className="btn btn-primary" onClick={submitForm} disabled={!currentDoctor.name }>
          Submit
        </button>
        <button className="btn btn-primary ms-3"  disabled={previewDisabled} onClick={handlePrint}>
          Preview Form
        </button>

      </div>
    </div>
  );
};

export default Consent_for_treatment;
