import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";
import axios from 'axios';
import { useState, useEffect } from 'react';

const SalesChart = () => {
  // const baseurl = process.env.REACT_APP_BASE_URL;
  // const [formData, setData] = useState([]);
  // const [id, setId] = useState('');

  // useEffect(() => {
  //   const idValue = localStorage.getItem('HID');
  //   if (idValue) {
  //     setId(idValue);
  //     getReport(idValue);
  //   }
  // }, []); // Fetch data whenever selectedFormType changes

  // useEffect(() => {
  //   const getReport = async (id) => {
  //     let url = `${baseurl}/formdetails/formByHospitalId/${id}`;
  //     try {
  //       const res = await axios.get(url);
  //       if (res.status === 200) {
  //         const formattedData = res.data;
  //         console.log(formattedData)
  //         setData(formattedData);
  //       }
  //     } catch (error) {
  //       console.error(`Failed to fetch data`);
  //     }
  //   };
  //   getReport();
  // }, [])

  // // Get today's date
  // const today = new Date();

  // // Filter form details filled today
  // const formsFilledToday = formData.formDetails.filter((form) => {
  //   const formDate = new Date(form.date);
  //   return formDate.toDateString() === today.toDateString();
  // });

  // // Filter form details filled in the previous seven days
  // const sevenDaysAgo = new Date(today);
  // sevenDaysAgo.setDate(today.getDate() - 7);
  // const formsFilledInPreviousSevenDays = formData.formDetails.filter((form) => {
  //   const formDate = new Date(form.date);
  //   return formDate >= sevenDaysAgo && formDate < today;
  // });

  // // Filter form details filled in the previous 30 days
  // const thirtyDaysAgo = new Date(today);
  // thirtyDaysAgo.setDate(today.getDate() - 30);
  // const formsFilledInPreviousThirtyDays = formData.formDetails.filter((form) => {
  //   const formDate = new Date(form.date);
  //   return formDate >= thirtyDaysAgo && formDate < today;
  // });

  // // Count the number of forms filled today, in the previous seven days, and in the previous 30 days
  // const countFormsFilledToday = formsFilledToday.length;
  // const countFormsFilledInPreviousSevenDays = formsFilledInPreviousSevenDays.length;
  // const countFormsFilledInPreviousThirtyDays = formsFilledInPreviousThirtyDays.length;

  // console.log("Forms filled today:", countFormsFilledToday);
  // console.log("Forms filled in the previous seven days:", countFormsFilledInPreviousSevenDays);
  // console.log("Forms filled in the previous thirty days:", countFormsFilledInPreviousThirtyDays);
  const chartoptions = {
    series: [
      {
        name: "Consent ",
        data: [10, 31, 40],
      },
      {
        name: "Procedure",
        data: [0, 11, 32],
      },
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: [
          "Today",
          "Weakly",
          "Monthly",
        ],
      },
    },
  };

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Form Print Summary</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Monthly Report
        </CardSubtitle>
        <Chart
          type="area"
          width="100%"
          height="390"
          options={chartoptions.options}
          series={chartoptions.series}
        />
      </CardBody>
    </Card>
  );
};

export default SalesChart;
