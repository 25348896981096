// import { createContext, useContext, useState } from "react";

// export const UserContext = createContext(null);

// export const UserContextProvider = (props) => {
//   const [user, setUser] = useState({}); // Initialize with null or a default value
//   const [Id , setId]=useState(null);
//   return (
//     <UserContext.Provider value={{ user, setUser,Id, setId }}>
//f       {props.children}
//     </UserContext.Provider>
//   );
// };



import { createContext, useContext, useState, useEffect } from "react";

export const UserContext = createContext(null);

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(() => {
    // Check if user data is stored in local storage
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : {};
  });
  const [id, setId] = useState(() => {
    // Check if Id is stored in local storage
    const storedId = localStorage.getItem("id");
    return storedId ? storedId : null;
  });
  



  useEffect(() => {
    // Update local storage when user or Id changes
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    // Update local storage when Id changes
    localStorage.setItem("id", id);
  }, [id]);
  //console.log("UserContextProvider render:", user);
  return (
    <UserContext.Provider value={{ user, setUser, id, setId }}>
      {props.children}
    </UserContext.Provider>
  );
};