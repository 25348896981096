
import "./Nurseassesmentsheet.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";
import { patientContext } from '../../../context/PatientsContext';
 import { SelectDoctorContext } from '../../../context/DoctorSelect';
 import { UserContext } from "../../../context/Usercontext";
 import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
const Nurse_sheet = () => {
      const {user}=useContext(UserContext);
    const baseurl = process.env.REACT_APP_BASE_URL;
    const {patient}= useContext(patientContext);
    const { currentDoctor } = useContext(SelectDoctorContext);
    const { uid } = useParams();
    // ============== print form =============================

    const handlePrint = () => {
        window.print();
    };


    //  ==================== Doctor list =================
    const [pdfData, setPdfData] = useState(null);

    const generatePDF = async () => {
        try {
            const input = document.getElementById('divToPrint');
            if (!input) {
                throw new Error('Element with id "divToPrint" not found.');
            }
    
            const canvas = await html2canvas(input);
            if (!canvas) {
                throw new Error('Canvas not generated.');
            }
    
            const imgData = canvas.toDataURL('image/jpeg');
            if (!imgData) {
                throw new Error('Image data not generated.');
            }
    
            const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
            //  pdf.save();
            // Convert PDF to Blob
            const blob = pdf.output('blob');
    
            // Set the Blob object to state
            setPdfData(blob);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    const [previewDisabled, setPreviewDisabled] = useState(true);
    const submitForm = async (e) => {
        // await generatePDF();
        e.preventDefault();
        let PID = patient.id;
        try {
            if (currentDoctor.name === "" || PID === "") {
                alert("Please select patient and doctor");
                return;
            }
            await generatePDF();

            // Create FormData object
            const formData = new FormData();
            formData.append('doctor_id', currentDoctor.id);
            formData.append('patient_id', PID);
            formData.append('date', new Date().toLocaleDateString());
            formData.append('hospital_id', user.hospital.id);
            formData.append('unique_master_form_id', uid);
            formData.append('image_link', pdfData, 'GeneratedForm.pdf');
    
            const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPreviewDisabled(false);
            alert(res.data.msg);
        } catch (error) {
            console.log(error);
            // alert(error.response.data.error);
        }
    };
    
  




    


    return (
        <div>
            <FormNavbar id="navbar" className="navbar" >
            </FormNavbar>
            <div className="container p-4" id="divToPrint">

                <div className="row">
                    <FormHeader></FormHeader>
                   
                </div>

                <div className="header">
                    <div
                        className="modal fade"
                        id="exampleModal1"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Attendant></Attendant>

                    </div>
                    <div
                        className="modal fade"
                        id="exampleModal2"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <AttendantSearch></AttendantSearch>
                        {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={select}>Select This Attendant</button>
                        </div> */}
                    </div>

                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <Patients >
                        </Patients>
                        {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                            <button className="btn btn-primary " onClick={Select}>Select This Patient</button>
                        </div> */}
                    </div>
                    <div
                        class="modal fade"
                        id="registrationModal"
                        tabIndex="-1"
                        aria-labelledby="registrationModalLabel"
                        aria-hidden="true"
                    >

                        <PatientRegister></PatientRegister>
                    </div>
                </div>

                {/* <hr class="seprator" /> */}
                <div class="form-container">
                    <div class="form-heading">
                        <h3>NURSES ASSESSMENT SHEET-ED</h3>
                    </div>
                    <div class="form-details">
                        <div class="line1">
                            <div class="name">
                                <p> Patient Name : <b>{patient.patient_name}</b></p>
                            </div>
                            <div class="sex">
                                <p>Age/Sex: <b>{patient.patient_age}  /&nbsp;  {patient.patient_gender} </b></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="consultant">
                                <p>MRD No. : .................................</p>
                            </div>
                            <div class="consultant">
                                <p>Consultant : <b>{currentDoctor.name}</b></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="datetime">
                                <p>Date & Time Receiving the Patient : <b>{ new Date().toLocaleDateString() + " , " +  new Date().toLocaleTimeString()}</b></p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="mlc">
                                <p>MLC :- </p>
                            </div>
                            <div class="yes">
                                YES <input type="checkbox" class="box" value="check" />
                            </div>
                            <div class="no">
                                NO <input type="checkbox" class="box" value="check" />
                            </div>
                            <div class="mlcno">
                                MLC No :-.........................................................
                            </div>
                        </div>
                        <div class="line1">
                            <div class="weight">
                                <p>
                                    Weight :-............................................................ID Band
                                </p>
                            </div>
                            <div class="yes1">
                                <input type="checkbox" class="box" value="check" /> YES
                            </div>
                            <div class="no1">
                                <input type="checkbox" class="box" value="check" /> NO
                            </div>
                        </div>
                        <div class="line1">
                            <div class="chief">
                                <p>
                                    Chief Complaints :.........................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ambulatory">
                                <p>
                                    Ambulatory Non  <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                            <div class="ambulatory">
                                <p>
                                    Ambulatory <input type="checkbox" class="box" />
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    Pulse :-..........................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    B.P :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    RR :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    Temperature :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    SPO2 :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="temp">
                                <p>
                                    RBS :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="patienton">
                                <p>
                                    Patient On :-
                                </p>
                            </div>
                            <div class="patienton">
                                <p>
                                    Room Air    <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                            <div class="patienton">
                                <p>
                                    Oxygen   <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ivline">
                                <p>
                                    IV Line :-
                                </p>
                            </div>
                            <div class="ivline">
                                <p>
                                    <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                            <div class="ivline">
                                <p>
                                    <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="ivline">
                                <p>
                                    Bed Score :-
                                </p>
                            </div>
                            <div class="ivline">
                                <p>
                                    <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                            <div class="ivline">
                                <p>
                                    <input type="checkbox" class="box" value="check" />
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="invesigation">
                                <p>
                                    Any Previous  Investigation Report :-.......................................................................
                                </p>
                            </div>
                        </div>
                        <div class="line1">
                            <div class="kth">
                                <p>
                                    KTH/IPD/PST/,013
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" onClick={submitForm} >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint} disabled={previewDisabled}>
                    Print Form
                </button>
            </div>
        </div>

    );
};



export default Nurse_sheet;