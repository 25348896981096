import React, { useContext , useState} from 'react'
import "./Adminforanaesthesia.css";
import FormHeader from '../../FormHeader';
import { patientContext } from '../../../context/PatientsContext';
 import { SelectDoctorContext } from '../../../context/DoctorSelect';
import FormNavbar from '../FormNavbar';
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import { UserContext } from '../../../context/Usercontext';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import axios from "axios";

const Admin_anaesthesia = (doctor1, doctorID) => {
    const baseurl= process.env.REACT_APP_BASE_URL;
    const { uid } = useParams();
    const { patient } = useContext(patientContext);
    const { currentDoctor} = useContext(SelectDoctorContext);
   const {user} =useContext(UserContext);
    // console.log(patient);\

     //  ==================== Doctor list =================
     const [pdfData, setPdfData] = useState(null);

     const generatePDF = async () => {
         try {
             const input = document.getElementById('divToPrint');
             if (!input) {
                 throw new Error('Element with id "divToPrint" not found.');
             }
     
             const canvas = await html2canvas(input);
             if (!canvas) {
                 throw new Error('Canvas not generated.');
             }
     
             const imgData = canvas.toDataURL('image/jpeg');
             if (!imgData) {
                 throw new Error('Image data not generated.');
             }
     
             const pdf = new jsPDF({ orientation: 'portrait', unit: 'mm', format: 'a4' });
             const imgWidth = pdf.internal.pageSize.getWidth();
             const imgHeight = (canvas.height * imgWidth) / canvas.width;
             pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
             //  pdf.save();
             // Convert PDF to Blob
             const blob = pdf.output('blob');
     
             // Set the Blob object to state
             setPdfData(blob);
         } catch (error) {
             console.error('Error generating PDF:', error);
         }
     };
     
     const [previewDisabled, setPreviewDisabled] = useState(true);
     const submitForm = async (e) => {
         // await generatePDF();
         e.preventDefault();
         let PID = patient.id;
         try {
             if (currentDoctor.name === "" || PID === "") {
                 alert("Please select patient and doctor");
                 return;
             }
             await generatePDF();
 
             // Create FormData object
             const formData = new FormData();
             formData.append('doctor_id', currentDoctor.id);
             formData.append('patient_id', PID);
             formData.append('date', new Date().toLocaleDateString());
             formData.append('hospital_id', user.hospital.id);
             formData.append('unique_master_form_id', uid);
             formData.append('image_link', pdfData, 'download.pdf');
     
             const res = await axios.post(`${baseurl}/formdetails/saveFormDetails`, formData, {
                 headers: {
                     'Content-Type': 'multipart/form-data'
                 }
             });
             setPreviewDisabled(false);
             alert(res.data.msg);
         } catch (error) {
             console.log(error);
             // alert(error.response.data.error);
         }
     };
     
   
 
 
  const handlePrint=()=>{
  window.print();
  }
    return (
        <div>
            <FormNavbar ></FormNavbar>
            <div className='p-4'  id="divToPrint">

            <FormHeader></FormHeader>

            <div className="header">
                <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <Attendant></Attendant>

                </div>
                <div
                    className="modal fade"
                    id="exampleModal2"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <AttendantSearch></AttendantSearch>
                    {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                        <button className="btn btn-primary " data-bs-dismiss="modal"
                            aria-label="Close" onClick={select}>Select This Attendant</button>
                    </div> */}
                </div>

                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <Patients >
                    </Patients>
                    {/* <div className="select p-2  bg-white  container  rounded  " id="select">
                        <button className="btn btn-primary " onClick={Select}
                            data-bs-dismiss="modal"
                            aria-label="Close">Select This Patient</button>
                    </div> */}
                </div>
                <div
                    class="modal fade mt-0"
                    id="registrationModal"
                    tabIndex="-1"
                    aria-labelledby="registrationModalLabel"
                    aria-hidden="true"
                >
                    <PatientRegister>
                    </PatientRegister>
                </div>
            </div>
            <div class="form-container">
                <div class="form-heading">
                    <h3>CONSENT FORM FOR ADMINISTRATION OF ANAESTHESIA</h3>
                </div>
                <div class="form-details">
                    <div class="line1">
                        <div class="name">
                            <p>Name : <b>{patient.patient_name}</b></p>
                        </div>
                        <div class="sex">
                            <p>Sex: <b>{patient.patient_gender}</b></p>
                        </div>
                        <div class="age">
                            <p>Age:  <b>{patient.patient_age}</b></p>
                        </div>
                    </div>
                    <div class="line1">
                        <div class="mr-no">
                            <p>MR No:...............................</p>
                        </div>
                        <div class="uhid">
                            <p>UHID No: <b>{patient.id}</b></p>
                        </div>
                        <div class="ipd">
                            <p>IPD No:..........................</p>
                        </div>
                        <div class="date">
                        <p>Date: <b>{new Date().toLocaleDateString()}</b></p>
                        </div>
                    </div>
                </div>
                <div class="line1">
                    <div class="sonof">
                        <p>
                            S/O , D/O, W/O :
                            ...........................................................
                        </p>
                    </div>
                </div>
                <div class="para">
                    <h4>Authorization for Administration of Anaesthesia</h4>
                    <ol>
                        <li>
                            I hereby authorize the above hospital and its staff to perform upon
                            the above patent the following surgical treatment under anaesthesia.
                        </li>
                        <li>
                            I consent to the administration of anaesthesia and to such
                            anaesthetics/sedativos / other drugs as may deemed necessary of
                            desirable.
                        </li>
                        <li>
                            The drugs which are given to produce anaesthesis and lessen pain may
                            cause drowsas or sleepiness or may numb a part of my body.
                        </li>
                        <li>
                            I know, must tell the doctors if I have had any other health problem
                            taking any medicines also must tell the doctor if have eaten or
                            taken alcohol since midnight.
                        </li>

                        <li>
                            It has been explained o me that during the course of the operation
                            procedure unforeseen conditions may be revealed or encountered which
                            necessitate surgical or other emergency procedures in addition to or
                            different from those contemplated at the time of the initial
                            diagnosis I, therefore further authorize above designated staff to
                            perform such additional surgical or other procedures as they deem
                            necessary or desirable.
                        </li>
                        <li>
                            I state that I am/am not suffering from
                            Hypertension/Diabetes/Bleeding disorder / heart disease or any known
                            allergy.
                        </li>
                        <li>
                            I further consent to the administration of anaesthetic drugs
                            infusion, blood product transfusions.
                        </li>
                        <li>
                            I have been given an opportunity to ask all any questions and I have
                            been given option to ask for second opinion.
                        </li>
                        <li>
                            I acknowledge that no guarantee and promise has been made to me
                            conceming the result of any procedure/ treatment.
                        </li>
                    </ol>
                </div>
                <div class="certify">
                    <p>
                        I CERTIFY THAT THE STATEMENT MADE IN THE ABOVE CONSENT FORM HAS BEEN
                        READ OVER END EXPLINED TO ME IN MY MOTHER TONGUE AND I HAVE FULLY
                        UNDERSTOOD THE MPLICATIONS OF THE ABOVE CONSENT
                    </p>
                </div>
                <div class="sign">
                    <div class="witness-name-sign">
                        <p>Name & Signature of the witness</p>
                        <p>--------------------------</p>
                        <p>--------------------------</p>
                    </div>
                    <div class="patients-sign">
                        <p>Signature of the patients /Parent/Gurdian</p>
                        <p>Thumb Impression</p>
                        <p>Name: ......................</p>
                        <p>Relationship With Patients : ...................</p>
                    </div>
                </div>
                <div class="cerify1">
                    <p> I CONFIRM THAT I HAVE EXPLANIED THAT NATURE AND EFFECTS OF THE OPERATION/

                        OCEDUE/THREATMENT TO THE PERSON WHO HAS SIGNED THE ABOVE CONSENT FORM
                    </p>
                </div>
                <div class="sign-anaes">
                    <p> Signature Of Anaesthetist</p>
                    <p> Name : <b>{currentDoctor.name}</b></p>
                </div>
            </div>
            </div>
            <div className="button">
                <button className="btn btn-secondary me-3" onClick={() => { window.history.back() }} >Back</button>
                <button className="btn btn-primary me-3" onClick={submitForm} >
                    Submit
                </button>
                <button className="btn btn-primary" onClick={handlePrint} disabled={previewDisabled}>
                    Print Form
                </button>
            </div>
        </div>
    );
};

export default Admin_anaesthesia;