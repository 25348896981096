import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";

import logo1 from "../assets/images/logos/logo5.png"
import { useContext } from "react";
import { UserContext } from "../context/Usercontext";

const Header = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const user = useContext(UserContext).user;

  const getLogoSrc = () => {

    if (user && user.hospital && user.hospital.logo) {
      return user.hospital.logo;
    }
    return null;

  };
  const name = () => {
    if (user && user.hospital && user.hospital.name) {
      return user.hospital.name;
    }
    return null;
  }
  const id = () => {
    if (user && user.hospital && user.hospital.id) {
      return user.hospital.id;
    }
    return null;
  }
  const email = () => {
    if (user && user.hospital && user.hospital.email) {
      return user.hospital.email;
    }
    return null;
  }
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const logout = () => {
    localStorage.clear();
    // localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <Navbar className=" bg-primary-subtle " expand="md">
      <div className="d-flex align-items-center">
        <NavbarBrand href="/" className="d-lg-none">
        <img src={logo1} className="rounded-circle" alt="avatar" width="45" height="45" />
        </NavbarBrand>
        <Button
          color="primary"
          className="d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto " navbar>
          <NavItem>
          <img
              className="rounded-circle"
              src={`data:image/png;base64,${getLogoSrc()}`}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
          </NavItem>
          <NavItem>
            <h4 className=" ms-2 text-primary "> {name()}</h4>
          </NavItem>
          {/* <NavItem>
            <Link to={`/dashboard/${id}`} className="nav-link">
              Starter
            </Link>
          </NavItem>
          <NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              DD Menu
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>Option 1</DropdownItem>
              <DropdownItem>Option 2</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Reset</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </Nav>
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-flex" >
          {/* <div class="gap-3 d-flex me-2">

          </div> */}
          <DropdownToggle className=" gap-3   bg-primary-subtle border-primary-subtle  m-0 p-1" >
            
            <a href="#" class=" me-3 list-group-item-primary"><i class="bi bi-envelope fs-4"></i></a>
              <a href="#" class="ms-3 list-group-item-primary"><i class="bi bi-gear fs-4"></i></a>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Info</DropdownItem>
            <DropdownItem>My Account</DropdownItem>
            <DropdownItem>Edit Profile</DropdownItem>
            <DropdownItem divider />
            <DropdownItem></DropdownItem>
            <DropdownItem>{email()}</DropdownItem>
            <DropdownItem> ID :{id()}</DropdownItem>
            <DropdownItem onClick={logout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
    </Navbar>
  );
};

export default Header;
