import { Button, Nav, NavItem } from "reactstrap";
// import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/Usercontext";
import logo1 from "../assets/images/logos/logo5.png"
const Sidebar = () => {
  const { id, userType } = useContext(UserContext);

  let navigation = [
    {
      title: "Dashboard",
      href: `/dashboard/${id}`,
      icon: "bi bi-speedometer2",
    },
    {
      title: "Forms",
      href: "/formmanager",
      icon: "bi bi-book",
    },

    // {
    //   title: "New Patient",
    //   href: "/newpatient",
    //   icon: "bi bi-person-add",
    // },
 
    {
      title: "Patient",
      href: "/allpatients",
      icon: "bi bi-person-video2",
    },
    {
      title: "Printed Forms",
      href: "/report",
      icon: "bi bi-book",
    },
    // {
    //   title: "About",
    //   href: "/about",
    //   icon: "bi bi-people",
    // },
    {
      title: " Doctors",
      href: "/doctors",
      icon: "bi bi-people",
    },
  ];
  
 

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  let location = useLocation();

  return (
    <div className="p-3 bg-light-subtle" dark>
      <div className="d-flex align-items-center">
        {/* <Logo />  */}
        <img src={logo1} className="rounded-circle" alt="avatar" width="45" height="45" />
        <h4> Suvidha Form
        </h4>
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none"
            onClick={() => showMobilemenu()}
          ></Button>
        </span>
      </div>
      <div className="pt-4 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "text-primary nav-link py-3"
                    : "nav-link text-secondary py-3"
                }
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;

