import { useMemo, useState, useEffect } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Report = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const imageurl = process.env.REACT_APP_IMAGE_URL;
    const [data, setData] = useState([]);
    const [selectedFormType, setSelectedFormType] = useState("all"); // State to store selected form type filter
    const [id, setId] = useState('');
    useEffect(() => {
        const idValue = localStorage.getItem('HID');
        if (idValue) {
            setId(idValue);
            getReport(idValue);
        }
    }, [selectedFormType]); // Fetch data whenever selectedFormType changes

    const getReport = async (id) => {
        let url = `${baseurl}/formdetails/formByHospitalId/${id}`;
        if (selectedFormType !== "all") {
            url = `${baseurl}/formdetails/formsByTypeHid/${id}/${selectedFormType}`;
        }
    
        try {
            const res = await axios.get(url);
            if (res.status === 200) {
                let count = 0;
                const formattedData = res.data.formDetails.map(form => ({
                    id: ++count,
                    date: form.date,
                    form_type: form.form_type,
                    patient_id: form.patient_id,
                    patient_name: form["patient.patient_name"],
                    doctor_name: form["doctor.name"],
                    pdf: `${imageurl}/${form.image_link}`
                }));
                setData(formattedData);
            }
        } catch (error) {
            console.error(`Failed to fetch data`);
        }
    }
    

    const handleChangeFormType = (event) => {
        setSelectedFormType(event.target.value); // Update selected form type filter
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'S.No',
                size: 15,
            },
            {
                accessorKey: 'date',
                header: 'Date',
                size: 150,
            },
            {
                accessorKey: 'form_type',
                header: "Type of Forms",
                size: 150,
            },
            {
                accessorKey: 'patient_id',
                header: 'Patients ID',
                size: 200,
            },
            {
                accessorKey: 'patient_name',
                header: 'Patient Name',
                size: 200,
            },
            {
                accessorKey: 'doctor_name',
                header: 'Doctor Name',
                size: 150,
            },
            {
                accessorKey: 'pdf',
                header: 'PDF',
                size: 100,
                Cell: ({ row }) => (
                    <a href={row.original.pdf} target="_blank" rel="noreferrer"><i class="bi bi-filetype-pdf text-danger fs-5"></i></a>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
    });

    return (
        <>
         
            <div className="dashboard p-2 mb-3 bg-body-tertiary rounded " >
                <h3 className='text-primary  p-3 text-opacity-75'> Printed Forms </h3>
                <div className="mb-3 col-3 d-flex mt-2 mb-2 col">
                    <select
                        className="form-select" aria-label="Default select example"
                        value={selectedFormType}
                        onChange={handleChangeFormType}>
                        <option value="all" selected className="form-control">All</option>
                        <option value="Consent" className="form-control">Consent</option>
                        <option value="Procedure" className="form-control">Procedure</option>
                    </select>
                </div>
                <MaterialReactTable table={table} />
            </div>
            <div className="copyright text-center m-0 fixed-bottom ">
                Developed By @
                <Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">
                    Tecraki Technology Solutions PVT. LTD.
                </Link>
            </div>
        </>
    );
};

export default Report;
