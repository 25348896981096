import React, { useContext } from 'react';
import { UserContext } from '../context/Usercontext';

const FormHeader = () => {
    const { user } = useContext(UserContext);
    // console.log(user);

    return (
        <>
           
                <div>
                    <div className="row">
                        <div className="col-3 text-end align-self-center">
                            <div className="logo">
                                <img
                                    className="logo-img"
                                    src={`data:image/png;base64,${user.hospital.logo}`}
                                    alt="Hospital Logo"
                                    style={{ maxWidth: "200px", maxHeight: "100px" }}
                                />
                            </div>
                        </div>
                        <div className="col-9">
                            <b>
                                <h1 id="hospitalName" className="m-1">
                                    {user.hospital.name}
                                </h1>
                            </b>
                            <p id="hospitalAddress">
                                <b>
                                    {user.hospital.address + "( " + user.hospital.pincode + " )" + ", " + user.city_name + ", " + user.state_name}
                                </b>
                            </p>
                            <p>
                                <b>
                                    Phone - {user.hospital.phone_primary + " ," + user.hospital.phone_secondary + ", Email : " + user.hospital.email}
                                </b>
                            </p>
                        </div>
                    </div>
                    <hr />
                </div>
         
        </>
    );
}

export default FormHeader;
