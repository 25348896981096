import React, { useState, useEffect, useRef } from "react";
import "./Hivpretesting.css";
import axios from "axios";
import FormNavbar from "../FormNavbar"
import Attendant from "../Attendant";
import Patients from "../Patients"
import PatientRegister from "../PatientRegister"
import AttendantSearch from "../AttendantSearch"
import FormHeader from "../../FormHeader";
import { PDFDocument } from "pdf-lib";
import html2canvas from "html2canvas";

const Hiv_testing = (doctor1, doctorID) => {
  const baseurl = process.env.REACT_APP_BASE_URL;
 
  // ============== print form =============================

  const handlePrint = () => {
    window.print();
  };


  //  ==================== Doctor list =================



  return (
    <div>
      <FormNavbar id="navbar" className="navbar" >
      </FormNavbar>
      <div className="main-header container" >
       <FormHeader></FormHeader>
        <div className="header">
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Attendant></Attendant>

          </div>
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <AttendantSearch></AttendantSearch>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " data-bs-dismiss="modal"
                aria-label="Close" onClick={select}>Select This Attendant</button>
            </div> */}
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Patients >
            </Patients>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " data-bs-dismiss="modal"
                aria-label="Close"  onClick={Select}>Select This Patient</button>
            </div> */}
          </div>
          <div
            class="modal fade"
            id="registrationModal"
            tabIndex="-1"
            aria-labelledby="registrationModalLabel"
            aria-hidden="true"
          >

            <PatientRegister></PatientRegister>
          </div>
        </div>

        {/* <hr class="seprator" /> */}
        <div class="form-container">
          <div class="form-heading">
            <h3>HIV Pre - Testing Information and Consent</h3>
          </div>
          <div class="pre-test-info">
            <h5>Pre - Testing Information:</h5>
            <ol>
              <li>
                HIV-Human Immunodeficiency Virus. Also known as HTLV (human t-cell
                lymphotropic virus type III) or LAV (Lymphadenopathy Associated
                Virus.) This is the "AIDS" Virus.
              </li>
              <li>
                HIV Antibody Test I & II this is the initial test for detection of
                exposure to HIV in the blood. Antibodies are substance made by the
                body to fight infection
              </li>
            </ol>
          </div>
          <div class="pre-test-info">
            <h5>A Negative Test can mean :</h5>
            <ol>
              <li>
                That you have not been exposed to or infected by the AIDS virus
              </li>
              <li>
                That you have been exposed to the AIDS virus, but your body has not
                had enough time to make antibodies. If his this is possibility, your
                doctor may recommend repeating the lest during the next 3 to 6
                months
              </li>
              <li>That the test was falsely negative for technical reasons.</li>
            </ol>
          </div>
          <div class="pre-test-info">
            <h5>A Positive Test can mean :</h5>
            <ol>
              <li>
                You do not necessarily have AIDS. Your blood should be sent for
                another, more specific and confirmatory test.
              </li>
              <li>
                If another HIV antibody test return positive, then you most likely
                been exposed to and have AIDS virus and you should consider yourself
                capable of passing the virus to other.
              </li>
            </ol>
          </div>
          <div class="pre-test-info">
            <h5>This test it recommended for the following high-risk groups:</h5>
            <ol>
              <li>
                Homosexuality even one homosexual encounter, since 1976 puts a
                person at risk
              </li>
              <li>Bisexuality</li>
              <li>
                Blood transfusions any transfusion between 1976 and 1985 may have
                caused infectio
              </li>
              <li>
                Multiple sexual partners Multiple heterosexual or homosexual
                partners increase th infection.
              </li>
              <li>
                Prostitution Persons born in a country with a high incidence of
                heterosexual tra persons immigrating from Haiti, Central Africa etc
                since 1977 are at increased risk. It is in mention that PREVENTION
                is the best policy in dealing with AIDS (or a positive HIV test) and
                transmission it is important to avoid the following.
                <ol>
                  <li>Sexual contact with persons suspected of AIDS virus.</li>
                  <li>Sexual contact with multiple partners.</li>
                  <li>
                    Intravenous drug abuse or sexual contact with people who use
                    intravenous drugs.
                  </li>
                  <li>
                    Oral-genital contact or open-mouthed kissing with high-risk
                    contacts.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div class="para">
            <p>
              It is recommended that you and your partner use condoms as protection
              against the transmission of the AIDS virus. However, it should be
              noted that while the use of condoms is considered to be a "Safe Sexual
              Practice", it should not be considered 100% effective, and, therefore,
              it is important for you to choose partners carefully and to avoid high risk
              behaviours as noted in the guideline Please feel free to consult your
              primary care physician with any questions you may have about this  Information.
            </p>
          </div>
          <p><b>Test Result will be confidential</b></p>
        </div>

      </div>
      <div className="button">
      <button className="btn btn-secondary me-3" onClick={()=>{ window.history.back()}} >Back</button>
        {/* <button className="btn btn-primary me-3">
          Submit
        </button> */}
        <button className="btn btn-primary" onClick={handlePrint}>
          Print Form
        </button>
      </div>
    </div>
  );
};


export default Hiv_testing;