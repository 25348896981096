import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backgroundImg from "../assets/images/bg/Resetpassword (2).png";
import { Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { validatePassword } from "../Validation";
import axios from "axios";
const ForgotPassword = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const { resetToken } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
      setIsValidPassword(validatePassword(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setIsValidConfirmPassword(value === password);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission
    console.log(resetToken)
    try {
      const response = await axios.post(`${baseurl}/api/user/reset-password`, {
        "resetToken": resetToken,
        "password": password
      });
      if (response.status === 200) {
        alert("reset Succesfully")
        navigate("/login");
      }
    } catch (error) {
      alert(error.message);
      console.error("Error resetting password:", error);
    }

  };
  return (
    <div>
      <div className="container d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        <div className="row align-self-center bg-light-subtle rounded" style={{ width: "80%", alignItems: "center" }}>
          <div className="col-sm-6 p-3 registration rounded">
            <img className="img-fluid rounded" src={backgroundImg} style={{ width: "100%" }} alt="" />
          </div>
          <div className="col-sm-6 p-3 rounded">
            <h4 className="text-center p-3 login_heading">Reset Password</h4>
            {/* Form for password reset */}
            <Form className="p-3" onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="password">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={handleChange}
                  placeholder=""

                  invalid={!isValidPassword} // Set invalid state if password is invalid
                  required
                />
                {/* Display validation feedback if password is invalid */}
                <FormFeedback>Password must be at least 8 characters long.</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  value={confirmPassword}

                  onChange={handleChange}
                  placeholder=""
                  invalid={!isValidConfirmPassword} // Set invalid state if passwords don't match
                  required
                />
                {/* Display validation feedback if passwords don't match */}
                <FormFeedback>Passwords do not match.</FormFeedback>
              </FormGroup>
              <button type="submit" className="btn btn-primary">
                Reset Password
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
