import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { AttendantContext } from '../../context/AttendentContext';
const AttendantSearch = () => {
    const {attendant,setAttendant}=useContext(AttendantContext);
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [attendantDetail, setAttendantDetail] = useState("");
    const [id, setId] = useState("");
    const [hid, setHid] = useState("");

    useEffect(() => {
        const HID = localStorage.getItem("HID");
        setHid(HID);
    }, []);


    const handleChange = (e) => {
        setId(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            let response;
            const formattedInput = id.replace(/\s/g, '');

            if (formattedInput.length === 10 && !isNaN(formattedInput)) {
                response = await axios.get(`${baseurl}/attendant/getattendants/${hid}/${formattedInput}?searchType=telephone`);

            } else {
                response = await axios.get(`${baseurl}/attendant/getattendants/${hid}/${formattedInput}?searchType=id`);
            }


            if (response.data.attendant) {

                const attendant = response.data.attendant;
                const attendantId = response.data.attendant.id;
                setAttendant(attendant);
                setAttendantDetail(attendant);
                localStorage.setItem('attendantId', JSON.stringify(attendantId));
            } else {

                alert("Attendant not found")

            }
        } catch (error) {
            setAttendantDetail("");
            alert(error.response.data.msg);
        }
    }

    // console.log(attendant);
    const clearAttendant=()=>{
        setAttendant("")
        setAttendantDetail("");
    }

    return (
        <div className="modal-dialog modal-xl mb-0">
            <div className="modal-content  ">
                <div className="modal-header">
                    {/* <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Search For Attendant
                    </h1> */}
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body p-4 bg-body-tertiary rounded  m-4">
                    <div className="row mt-3 Patients">
                        <div className="col-md-6  rounded">
                            <h4 className=''>Search Attendant</h4>
                            <hr />
                            <form  onSubmit={handleSearch}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <label htmlFor="" className="form-label">
                                            Search Attendant
                                        </label>
                                        <input
                                            type="text"
                                            className="col-3 form-control"
                                            placeholder="Enter ID / Phone "
                                            id="searchInput"
                                            name="searchInput"
                                            value={id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-3 mt-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-3"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 bg-light rounded">
                            <h4>Attendant Details</h4>
                            <hr />
                            <div className="details d-flex">
                                <div className="name">
                                    <p>
                                        Name: &nbsp; <b id='pname'>{attendantDetail.name}</b>
                                    </p>
                                    <p>
                                        Patient ID: &nbsp; <b id='pid'>{attendantDetail.id}</b>
                                    </p>
                                    <p>
                                        Phone: &nbsp; <b id='pphone'>{attendantDetail.telephone}</b>
                                    </p>
                                </div>
                                <div className="name">
                                    <p>
                                        Occupation: &nbsp; <b id=''>{attendantDetail.occupation}</b>
                                    </p>
                                    <p>
                                        Address: &nbsp; <b id='paddress'>{attendantDetail.address}</b>
                                    </p>
                                    <button className='btn btn-outline-primary mt-2' onClick={()=>clearAttendant()}>Clear Attendant  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        
                <br />
            </div>

        </div>
    );
}

export default AttendantSearch;