import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import PatientRegister from './PatientsRegistration';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Allpatients = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState([]);
    const [id, setId] = useState('');

    useEffect(() => {
        const idValue = localStorage.getItem('HID');
        setId(idValue);
    }, []);

    useEffect(() => {
        if (id) {
            getPatients();
        }
    }, [id]);

    const getPatients = async () => {
        try {
            const res = await axios.get(`${baseurl}/patient/getAllPatientsByHid/${id}`);
            setData(res.data.patients);
        } catch (error) {
            console.error("Error while Fetching Patients Details:", error);
            // alert("Error while Fetching Patients Details");
        }
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 150,
        },
        {
            accessorKey: 'patient_name',
            header: 'Name',
            size: 150,
        },
        {
            accessorKey: 'patient_age',
            header: 'Age',
            size: 150,
        },
        {
            accessorKey: 'patient_address',
            header: 'Address',
            size: 200,
        },
        {
            accessorKey: 'patient_phone',
            header: 'Phone Number',
            size: 150,
        },
    ], []);

    const table = useMaterialReactTable({
        columns,
        data,
    });
    const [showModal, setShowModal] = useState(false);

    // Function to toggle the modal visibility
    const toggleModal = () => {
        setShowModal(!showModal);
        getPatients();
    };
    return (
        <>
        
            <div className="dashboard p-2 mb-3 bg-body-tertiary rounded">
                
                <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='text-primary p-3 rounded text-opacity-75'>Patients List</h3>
                    <button onClick={toggleModal} type="button" className="btn btn-outline-primary">
                        <i className="bi bi-plus-circle">&nbsp;  Add Patient</i>
                    </button>
                </div>
                <MaterialReactTable table={table} className="custom-material-table bg-body-tertiary" />
            </div>
            <div className="copyright text-center m-0 fixed-bottom">
                Developed By @
                <Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">
                    Tecraki Technology Solutions PVT. LTD.
                </Link>
            </div>
            <PatientRegister showModal={showModal} toggleModal={toggleModal} />
        </>
    );
}

export default  Allpatients;
