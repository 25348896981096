

// export default Login;
import React, { useContext, useState } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import backgroundImg from '../assets/images/bg/login2.svg';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../assets/images/logos/logo5.png"
import axios from 'axios';
import { useStepContext } from '@mui/material';
const Login = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;

  const [inputFields, setInputFields] = useState({
    email: '',
    password: ''
  });
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });

    switch (name) {
      case 'email':
        setEmailValid(validateEmail(value));
        break;
      case 'password':
        setPasswordValid(validatePassword(value));
        break;
      default:
        break;
    }
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePassword = (password) => {
    return /^(?=.*[!@#$%^&*])(?=.*\d).{8,}$/.test(password);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailValid && passwordValid) {
      try {
        const response = await axios.post(`${baseurl}/user/login`, inputFields);
        if(response.status === 200) {
          
          const token = response.data.token;
          const id = response.data.user.id;
          const HID= response.data.hospitalId;
          localStorage.setItem('HID', HID);
          localStorage.setItem('token', token);
        
          // user Id 
          localStorage.setItem('id',id);
           // ;navigate with userId 
          navigate(`/dashboard/${id}`);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setLoginMessage(error.response.data.error);
        } else {
          setLoginMessage(" Network Error");
        }
      }
    }
  };

   const[type,setType]= useState("password");
    const[show,setShow]= useState(true);
     const showPassword=()=>{
       setShow(!show);
       if(show){
         setType("password");
       }
       else{
         setType("text");
       }
     }


  return (
    <>
      <nav className="navbar navbar-expand-lg  back">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand"> <img src={logo} className="logoNav me-2" alt="" />Suvidha Form</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

        </div>
      </nav>
      <div className="container d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        <div className="row align-self-center bg-light-subtle rounded" style={{ width: "80%", alignItems: 'center' }}>
          <div className="col-sm-6 p-3 login1 rounded">
            <img className='img-fluid rounded' src={backgroundImg} style={{ width: '100%' }} alt="" />
          </div>
          <div className="col-sm-6 p-3 rounded">
            <h4 className='text-center p-3 login_heading'> Hospital Login</h4>
            <Form onSubmit={handleSubmit} className='p-3'>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={inputFields.email}
                  onChange={handleChange}
                  valid={emailValid}
                  invalid={!emailValid && inputFields.email !== ''}
                />
                <FormFeedback valid>Email is valid</FormFeedback>
                <FormFeedback invalid>Please enter a valid email address</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <div className='d-flex gap-1'>

                <Input
                  type={type}
                  name="password"
                  value={inputFields.password}
                  onChange={handleChange}
                  valid={passwordValid && passwordValid}
                  invalid={!passwordValid && inputFields.password !== ''}
                />
                {
                  show ?<><button type='button' onClick={()=>{showPassword()}} className='border-0 bg-none'><i class="bi bi-eye-slash fs-4"></i></button></>:<>
                  <button type='button' onClick={()=>{showPassword()}} className='border-0 bg-none'><i class="bi bi-eye-fill fs-4"></i></button>
                  </>
                }
                
                </div>
                <FormFeedback valid>Password is Valid</FormFeedback>
                <FormFeedback invalid>Password must be at least 8 characters with Special & Number </FormFeedback>
              </FormGroup>
              <p><Link to="/forgetpassword">Forget Password</Link></p>
              {loginMessage && <div className='text-danger' >{loginMessage}</div>}
              <button type="submit" className="btn btn-primary">Login</button>
            </Form>
            <p className='text-center'>Already have an account? <Link to="/register">Register</Link></p>
          </div>
        </div>
      </div>
      <footer className="footer fixed-bottom bg-light text-center p-0 copyright">
        Developed By @ <Link to="https://tecraki.io" className='text-decoration-none  text-secondary fw-semibold  '>Tecraki Technology Solutions PVT. LTD.</Link>
      </footer>
    </>
  );
};

export default Login;
