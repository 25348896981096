import { createContext ,useState } from "react";

export const AttendantContext=createContext(null);
export const AttendantProvider=(props)=>{
    const [attendant,setAttendant]=useState("");
    const [attendantID,setAttendantID]=useState("");
    return(
        <AttendantContext.Provider value={{attendant,setAttendant,attendantID,setAttendantID}}>
            {props.children}
        </AttendantContext.Provider>
    )
};