

// import React, { useEffect, useState } from "react";


// import { Link } from "react-router-dom";
// import { Input } from "reactstrap";
// import axios from "axios";

// const FormManager = () => {
//   const baseurl= process.env.REACT_APP_BASE_URL;
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedOption, setSelectedOption] = useState("all");
//   const [data, setData1]=useState("");
//   const[link,setLink]=useState("");
//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleSelectChange = (event) => {
//     setSelectedOption(event.target.value);
//     setSearchQuery(""); // Reset search query when option changes
//   };

//   // Array containing all form data
//   // const allForms = [
//   //   { name: "Consent Form For Treatment", link: "/forms/Consent", key: "consent" },
//   //   { name: "Consent Form For HIV Testing", link: "/forms/hiv", key: "consent" },
//   //   { name: "Consent From For Surgical Operation", link: "/forms/operation", key: "consent" },
//   //   { name: "HIV Pre - Testing Information and Consent", link: "/forms/hivtesting", key: "consent" },
//   //   { name: "Consent From For Surgical Operation", link: "/forms/operation", key: "consent" },
//   //   { name: "HIV Pre - Testing Information and Consent", link: "/forms/hivtesting", key: "consent" },
//   //   { name: "Care Plan", link: "/forms/care", key: "procedure" },
//   //   { name: "Nursing Admission Assessment", link: "/forms/nurseadmission", key: "procedure" },
//   //   { name: "Doctor Assesment Sheet-ED", link: "/forms/doctorsheet", key: "procedure" },
//   //   { name: "Nurses Assessment Sheet-ED", link: "/forms/nursesheet", key: "procedure" },
//   //   { name: "Pre Anaesthetic Checkup", link: "/forms/precheckup", key: "procedure" },
//   //   { name: "Nutrition Screening", link: "/forms/nutrition", key: "procedure" },
//   //   { name: "OT Notes", link: "/forms/otnote" },
//   //   { name: "Post Operative Plan Of Care", link: "/forms/postcareplan", key: "procedure" },
//   //   { name: "Post Operative Instruction", link: "/forms/postinstraction", key: "procedure" },
//   //   { name: "Pre Operative Checklist", link: "/forms/prechecklist", key: "procedure" },
//   //   { name: "Anaesthetic Record Pre-Operative Evaluation Form ", link: "/forms/anaestheticevalution", key: "procedure" },
//   //   { name: "Nursing Clinical Assessment ", link: "/forms/nursingclinic", key: "procedure" },
//   //   { name: "Patient Feedback Form ", link: "/forms/patientfeedback", key: "procedure" },
//   //   { name: "CONSENT FORM FOR ADMINISTRATION OF ANAESTHESIA ", link: "/forms/admin", key: "consent" },
//   // ];

//   // Filter forms based on search query and selected option
//   const filteredForms = allForms.filter(form => {
//     if (selectedOption === "all") {
//       return form.name.toLowerCase().includes(searchQuery.toLowerCase());
//     } else {
//       return form.key === selectedOption && form.name.toLowerCase().includes(searchQuery.toLowerCase());
//     }
//   });

//   useEffect(() => {
//     getForm();
//   },[])

//     const getForm =async()=>{
//       try {
//         const res= await axios.get(`${baseurl}/form/`);
//         if(res.status===200){// console.log(res.data.form.content);
//           setForm(res.data.form)

//         }

//       } catch (error) {
//          console.log(error)
//       }

//     }

//  const[form, setForm]=useState("");
//   return (
//     <>

//       <div className="dashboard" id="dashboard">
//         <div className="p-2 bg-body-tertiary  rounded">
//           <div className="container-fluid p-2">
//               <div className="row m-0">
//                 <div className="form-search col-3 d-flex mt-2 mb-2 p-0 col">
//                   <Input
//                     type="text"
//                     className="form-control"
//                     placeholder="Search by Form Name"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                   />
//                   <i className="bi bi-search fs-4 ms-2"></i>
//                 </div>

//                 <div className="form-search col-3 d-flex mt-2 mb-2 ms-4">
//                   <select
//                     name="formType"
//                     className="form-select" aria-label="Default select example"
//                     value={selectedOption}
//                     onChange={handleSelectChange}
//                   >
//                     <option value="all" className="form-control p-2">All</option>
//                     <option value="consent" className="form-control p-2">Consent</option>
//                     <option value="procedure" className="form-control p-2">Procedure</option>
//                   </select>
//                 </div>
//               </div>
//             <div className="scrollable-table rounded  table-responsive" style={{ maxHeight: '72vh', overflowY: 'auto' }}>
//               <table className="table table1">
//                 <thead>
//                   <tr>
//                     <th className="text-center align-self-center">SN</th>
//                     <th className="align-self-center">Form Name</th>
//                     <th className="text-center align-self-center">Link</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {filteredForms.map((form, index) => (
//                     <tr key={index} className="">
//                       <td className="text-center  align-middle">{index + 1}</td>
//                       <td className=" align-middle">{form.name}</td>
//                       <td className="text-center align-middle"><Link to={form.link} className="btn btn-outline-primary form-button">Open</Link></td>
//                     </tr>

//                   ))}
//                   {/* <tr>
//                     <td className="text-center p-0 align-middle">1</td>
//                     <td className="p-0 align-middle">Consent Form For Treatment</td>
//                     <td className="text-center p-0 align-middle"><Link to={`${link }/${uniq}`}>Open</Link></td>
//                   </tr> */}

//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="copyright text-center m-0 fixed-bottom">Developed By @<Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">Tecraki Technology Solutions PVT. LTD.</Link></div>
//     </>
//   );
// };

// export default FormManager;
import FormNavbar from "../components/formTemplates/FormNavbar";
import PatientRegister from "../components/formTemplates/PatientRegister";
import Attendant from "../components/formTemplates/Attendant";
import AttendantSearch from "../components/formTemplates/AttendantSearch";
import Patients from "../components/formTemplates/Patients";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import axios from "axios";
import { patientContext } from "../context/PatientsContext";
import { SelectDoctorContext } from "../context/DoctorSelect";
import { AttendantContext } from "../context/AttendentContext";
const FormManager = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("all");
  const [forms, setForms] = useState([]);

   const{patient}=useContext(patientContext);
   const{currentDoctor}=useContext(SelectDoctorContext);
   const{attendant}=useContext(AttendantContext);
  //  console.log(patient);
  //  console.log(currentDoctor);
  //  console.log(attendant);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSearchQuery(""); // Reset search query when option changes
  };

  useEffect(() => {
    getForms();
  }, []);

  const getForms = async () => {
    try {
      const res = await axios.get(`${baseurl}/form/`);
      if (res.status === 200) {
        setForms(res.data.forms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Filter forms based on search query and selected option
  const filteredForms = forms && forms.filter((form) => {
    if (selectedOption === "all") {
      return form.name.toLowerCase().includes(searchQuery.toLowerCase());
    } else {
      return (
        form.type === selectedOption && // Change 'key' to 'type'
        form.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  });

  const[formValue, setValue]=useState("");

 const select = async (form_id) => {

  setValue({
    uniqueFormId: form_id,
    doctor_name: currentDoctor?.name || "",
    name: attendant?.name || "",
    address: attendant?.address || "",
    occupation: attendant?.occupation || "",
    telephone: attendant?.telephone || "",
    relation_with_patient: attendant?.relation_with_patient || "",
    signature: attendant?.signature || "",
    patient_name: patient?.patient_name || "",
    patient_age: patient?.patient_age || "",
    patient_gender: patient?.patient_gender || "",
    patient_phone: patient?.patient_phone || "",
    patient_state: patient?.patient_state || "",
    patient_city: patient?.patient_city || "",
    patient_pincode: patient?.patient_pincode || "",
    patient_address: patient?.patient_address || "",
    patient_id: patient?.id || "",
    doctor_id: currentDoctor?.id || ""
  });
  try {
    const res = await axios.post(`${baseurl}/formProcessing/saveProcessedForms`, formValue);
    if (res.status === 200) {
      alert("Form Submitted Successfully");
    }
  } catch (error) {
    console.error(error);
    alert(error.response.data.error);
  }
};

useEffect(() => {
  console.log(formValue);
}, [formValue]);


  return (
    <>
      <div className="dashboard" id="dashboard">
        <FormNavbar></FormNavbar>
        <div className="header">
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Attendant></Attendant>

          </div>
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <AttendantSearch></AttendantSearch>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " data-bs-dismiss="modal"
                aria-label="Close" onClick={getAttendant}>Select This Attendant</button>
            </div> */}
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <Patients >
            </Patients>
            {/* <div className="select p-2  bg-white  container  rounded  " id="select">
              <button className="btn btn-primary " onClick={Select}
                data-bs-dismiss="modal"
                aria-label="Close">Select This Patient</button>
            </div> */}
          </div>
          <div
            class="modal fade mt-0"
            id="registrationModal"
            tabIndex="-1"
            aria-labelledby="registrationModalLabel"
            aria-hidden="true"
          >
            <PatientRegister>
            </PatientRegister>
          </div>
        </div>
        <div className="p-2 bg-body-tertiary  rounded">
          <div className="container-fluid p-2">
            <div className="row m-0">
              <div className="form-search col-3 d-flex mt-2 mb-2 p-0 col">
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search by Form Name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <i className="bi bi-search fs-4 ms-2"></i>
              </div>

              <div className="form-search col-3 d-flex mt-2 mb-2 ms-4">
                <select
                  name="formType"
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="all" className="form-control p-2">
                    All
                  </option>
                  <option value="Consent" className="form-control p-2">
                    Consent
                  </option>
                  <option value="Procedure" className="form-control p-2">
                    Procedure
                  </option>
                </select>
              </div>
            </div>
            <div
              className="scrollable-table rounded  table-responsive"
              style={{ maxHeight: "72vh", overflowY: "auto" }}
            >
              <table className="table table1">
                <thead>
                  <tr>
                    <th className="text-center align-self-center">SN</th>
                    <th className="align-self-center">Form Name</th>
                    <th className="text-center align-self-center">Link</th>
                  </tr>
                </thead>
                
                <tbody>
                  {filteredForms.map((form, index) => (
                    <tr key={index} className="">
                      <td className="text-center align-middle">{index + 1}</td>
                      <td className="align-middle">{form.name}</td>
                      <td className="text-center align-middle">
                        <Link to= {`${form.link }/${form.uniqueFormId}`} className="btn btn-outline-primary form-button" >Open</Link>
                        {/* <Link  className="btn btn-outline-primary form-button" onClick={()=>{select(form.uniqueFormId)}}>Open</Link> */}
                      </td>
                    </tr>
                    
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center m-0 fixed-bottom">
        Developed By @
        <Link to="https://tecraki.io" className="text-decoration-none text-secondary fw-semibold">
          Tecraki Technology Solutions PVT. LTD.
        </Link>
      </div>
    </>
  );
};

export default FormManager;
