
import { Routes, Route, BrowserRouter, Router } from 'react-router-dom';
import Login from "../src/auth/Login";
import Registration from "../src/auth/Registration";
import FogotPassord from "../src/auth/Forgot_Password";
import ResetPassword from "../src/auth/Reset_Password";
import FullLayout from "../src/layouts/FullLayout";
import  Starter from "../src/views/Starter";
import About from '../src/views/About';
import Allpatients from './views/AllPatinets';
import FormManager from "./views/FormManager"
import PatientRegister from "./views/PatientsRegistration"
import Report from './views/Report';
import Landing from "./components/Landing"
import Doctors from './views/Doctors';
import AddDoctor from './views/AddDoctorsFirst';
import Consent_for_treatment from "./components/formTemplates/consent_for_treatment/Consent_for_treatment";
import Hiv_test from "./components/formTemplates/hivTest/Hiv_test";
import Ot_notes from "./components/formTemplates/ot-notes/Ot_notes";
import Care_plan from "./components/formTemplates/careplan/Care_plan";
import Surgical_opration from "./components/formTemplates/con-form-for-surgilcal-operation/Surgical_operation";
import Doctor_sheet from "./components/formTemplates/doctor-assesment-sheet-ed/Doctor_sheet_ed";
import Hiv_testing from "./components/formTemplates/hiv-pre-testing-info/Hiv_pre_testing";
import Nurse_sheet from "./components/formTemplates/nurse-assessment-sheet/Nurse_assesment_sheet";
import Nurse_admission from './components/formTemplates/nursing-admission-assesssment/Nurse_admission';
import Nursing_clinic from "./components/formTemplates/nursing_clinical_assesment/Nursing_clinic";
import Nutrition_screening from "./components/formTemplates/nutrition_screening_form/Nutrition_screening";
import Patient_feedback from './components/formTemplates/patient_feedback_form/Patient-feedback';
import Post_plan from "./components/formTemplates/post-opera-care-plan/Post_plan_care";
import Post_instraction from "./components/formTemplates/post-operative-instruction/Post_instruction";
import Pre_checkup from './components/formTemplates/pre-aneasthetic-checkup/Pre_checkup';
import Pre_checklist from './components/formTemplates/pre-operative-checklist/Pre_checklist';
import Anaesthetic_evalution from './components/formTemplates/anaesthetic-record-evalution/Anaesthetic_evalution';
import Admin_anaesthesia from './components/formTemplates/consent_for_admin_of_anaesthesia/Admin_of_anaesthesia';
import Adddoctors from './views/Adddoctors';
import FormHeader from './components/FormHeader';
import Formtest from './components/formTemplates/Formtest';
function App() {
  return (
    <div className="dark">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="login" element={<Login />} /> 
          <Route path="register" element={<Registration />} /> 
          <Route path="forgetpassword" element={<FogotPassord />} />
          <Route path="user/getToken/:resetToken" element={<ResetPassword />} />
          {/* This is private route */}
          {/* Pass FullLayout component directly */}
          <Route path="/" element={<FullLayout />}> 
            <Route path="/dashboard/:id" element={<Starter />} />
            {/* <Route path="/test" element={<Formtest />} />
            <Route path="/about" element={<About />} /> */}
            <Route path="/allpatients" element={<Allpatients />} />
            <Route path="/newpatient" element={<PatientRegister />} />
            <Route path="/formmanager" element={<FormManager />} />
            <Route path="/report" element={<Report />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/adddoctor" element={<AddDoctor />} />
            <Route path="/formhead" element={<FormHeader />} />
            <Route path="hospital/forms/Consent/:uid" element={<Consent_for_treatment />} />
            <Route path="hospital/forms/hiv/:uid" element={<Hiv_test />} />
            <Route path="hospital/forms/otnote/:uid" element={<Ot_notes />} />
            <Route path="hospital/forms/care/:uid" element={<Care_plan />} />
            <Route path="hospital/forms/operation/:uid" element={<Surgical_opration />} />
            <Route path="hospital/forms/admin/:uid" element={<Admin_anaesthesia />} />
            <Route path="hospital/forms/doctorsheet/:uid" element={<Doctor_sheet />} />
            <Route path="hospital/forms/hivtesting/:uid" element={<Hiv_testing />} />
            <Route path="hospital/forms/nursesheet/:uid" element={<Nurse_sheet />} />
            <Route path="hospital/forms/nurseadmission/:uid" element={<Nurse_admission />} />
            <Route path="hospital/forms/nursingclinic/:uid" element={<Nursing_clinic />} />
            <Route path="hospital/forms/nutrition/:uid" element={<Nutrition_screening />} />
            <Route path="hospital/forms/patientfeedback/:uid" element={<Patient_feedback />} />
            <Route path="hospital/forms/postcareplan/:uid" element={<Post_plan />} />
            <Route path="hospital/forms/postinstraction/:uid" element={<Post_instraction />} />
            <Route path="hospital/forms/precheckup/:uid" element={<Pre_checkup />} />
            <Route path="hospital/forms/prechecklist/:uid" element={<Pre_checklist />} />
            <Route path="hospital/forms/anaestheticevalution/:uid" element={<Anaesthetic_evalution />} />


          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
